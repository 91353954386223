//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Typography, Icon, Tooltip, Divider } from '@material-ui/core';
import { ContextMenu, MenuItem as ContextMenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { useTools } from '../../../hooks/useTools';
import { useSettings } from '../providers/SettingsProvider';
import { useTimeline } from '../providers/TimelineProvider';
import { queries } from '../utils/queries';

import { checkForGroupableTag } from '../../../utils/MoveUtils';
import { checkMoveNeedsSync } from '../utils/helpers';

import ManualIcon from '@material-ui/icons/NatureTwoTone';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

import { MoveTileTooltip, MoveTileStatusBar, MoveTileHangtag, MoveTileHangtagTooltip } from '../index';
import { MoveTagGroupableTooltip } from '../../../components/MoveComponents/MoveTagTooltips';
import DeadlineTooltip from '../../../components/MoveComponents/DeadlineTooltip';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTileDrive({ enrichedDrive = {} }) {
  const {
    goToMoveDetails,
    goToLaneDetails,
    getDriveTypeFromMove,
    getFormattedVehicleFromMove,
    getCleansedPhoneNumber,
    searchLocationOnGoogleMaps,
    searchLaneOnGoogleMaps,
  } = useTools();

  const { timelineMoveOffset, enableMoveIds, atRiskView } = useSettings();
  const { handleSyncOneMove } = useTimeline();

  const [unassignHangtag] = useMutation(queries.UNASSIGN_HANGTAG);

  const {
    move = {},
    isCompleted,
    isDeclined,
    isCanceled,
    isFailed,
    isRescheduled,
    pickupDelay,
    driveDelay,
    deliveryDelay,
    extraPickupDuration,
    extraDriveDuration,
    extraDeliveryDuration,
    minWidth,
    pickupWidthExtra,
    driveWidthExtra,
    deliveryWidthExtra,
    gapPx,
    zIndex,
    bgColor,
    animation,
    hasOpsTag,
    hasConciergeTag,
    hasLoanerTag,
    hasManualTag,
    hasRailyardTag,
    hasSlaTag,
    hasNotesTag,
    hasMoreTag,
    hasTags,
    deadlineMs,
  } = enrichedDrive;

  const customer = move.customer || {};
  const lane = move.lane || {};
  const pickup = lane.pickup || {};
  const delivery = lane.delivery || {};
  const hasHangtag = move?.hangtags?.[0]?.hash_id ? true : false;
  const priorHangtags = move?.hadHangtag?.aggregate?.count;
  const isTriangle = move.config ? move.config.triangle_move === true : false;

  const useStyles = makeStyles(theme => ({
    shell: {
      position: 'relative',
      marginLeft: gapPx,
      marginRight: timelineMoveOffset,
    },
    tile: {
      position: 'relative',
      display: 'flex',
      height: 64,
      borderRadius: theme.shape.paperRadius,
      animation: animation,
      cursor: 'pointer',
      overflow: 'hidden',
    },
    drive: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: minWidth,
      maxWidth: minWidth,
      height: 64,
      padding: theme.spacing(1),
      background: bgColor,
      color: theme.palette.text.contrast,
      overflow: 'hidden',
    },
    pickupExtra: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: pickupWidthExtra,
      maxWidth: pickupWidthExtra,
      height: 64,
      background: bgColor,
      backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 10px, #ffffff40 10px, #ffffff40 20px)`,
      opacity: 0.7,
      overflow: 'hidden',
    },
    driveExtra: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: driveWidthExtra,
      maxWidth: driveWidthExtra,
      height: 64,
      background: bgColor,
      backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 10px, #ffffff40 10px, #ffffff40 20px)`,
      opacity: 0.9,
      overflow: 'hidden',
    },
    deliveryExtra: {
      zIndex: zIndex,
      position: 'relative',
      minWidth: deliveryWidthExtra,
      maxWidth: deliveryWidthExtra,
      height: 64,
      background: bgColor,
      backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 10px, #ffffff40 10px, #ffffff40 20px)`,
      opacity: 0.7,
      overflow: 'hidden',
    },
    extraBox: {
      position: 'absolute',
      top: 64,
      left: 4,
      width: 64,
      transformOrigin: '0 0',
      transform: 'rotate(-90deg)',
    },
    extraTxt: {
      lineHeight: 1,
      fontSize: 10,
      fontWeight: 900,
      textAlign: 'center',
      color: theme.palette.text.contrast,
      opacity: 0.75,
      textShadow: '-1px 1px 1px #00000040',
    },
    overlayPast: {
      zIndex: 272,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#fff',
      opacity: 0.5,
      pointerEvents: 'none',
    },
    overlayPending: {
      zIndex: 271,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.shape.paperRadius,
      background: theme.palette.error.main,
      opacity: 0.5,
      pointerEvents: 'none',
    },
    overlayTxt: {
      zIndex: 273,
      position: 'absolute',
      bottom: 6,
      right: 8,
      lineHeight: 1,
      fontSize: 20,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      userSelect: 'none',
    },
    laneBox: {
      zIndex: 262,
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
    laneTxt: {
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 400,
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    laneBreak: {
      width: '100%',
      height: theme.spacing(0.25),
    },
    laneFade: {
      zIndex: 263,
      position: 'absolute',
      top: 0,
      right: 0,
      width: theme.spacing(2),
      height: '100%',
      background: `linear-gradient(90deg, ${bgColor}00, ${bgColor})`,
      pointerEvents: 'none',
    },
    anchorBox: {
      zIndex: 261,
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      maxHeight: 18,
    },
    anchorTxt: {
      lineHeight: 1,
      fontSize: 12,
      fontWeight: 400,
      color: `${theme.palette.text.contrast}a0`,
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    tagBox: {
      zIndex: 264,
      position: 'absolute',
      display: 'flex',
      flexWrap: 'nowrap',
      bottom: 0,
      right: 0,
    },
    tagFade: {
      zIndex: 265,
      width: theme.spacing(2),
      height: 26,
      background: `linear-gradient(90deg, ${bgColor}00, ${bgColor})`,
      pointerEvents: 'none',
    },
    tags: {
      display: 'flex',
      flexWrap: 'nowrap',
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
      background: bgColor,
    },
    tagIcon: {
      fontSize: 18,
      color: theme.palette.text.contrast,
    },
    tagIconShake: {
      fontSize: 18,
      color: theme.palette.text.contrast,
      animation: `$shake 2.5s linear infinite`,
    },
    triangle: {
      fontSize: 14,
      color: theme.palette.text.contrast,
    },
    tagTxt: {
      transform: 'translateY(5%)',
      margin: theme.spacing(0, 0.25),
      lineHeight: 1,
      fontSize: 18,
      fontWeight: 700,
      color: theme.palette.text.contrast,
      userSelect: 'none',
    },
    tooltip: {
      minWidth: 540,
      maxWidth: 720,
    },
    divider: {
      marginTop: 4,
      marginBottom: 4,
    },
    '@keyframes pulse': {
      '0%': {
        filter: 'brightness(75%)',
      },
      '50%': {
        filter: 'brightness(100%)',
      },
      '100%': {
        filter: 'brightness(75%)',
      },
    },
    '@keyframes shake': {
      '0%': {
        transform: 'translateX(0px)',
      },
      '78%': {
        transform: 'translateX(0px)',
      },
      '80%': {
        transform: 'translateX(-1px)',
      },
      '82%': {
        transform: 'translateX(2px)',
      },
      '84%': {
        transform: 'translateX(-2px)',
      },
      '86%': {
        transform: 'translateX(2px)',
      },
      '88%': {
        transform: 'translateX(-2px)',
      },
      '90%': {
        transform: 'translateX(2px)',
      },
      '92%': {
        transform: 'translateX(-2px)',
      },
      '94%': {
        transform: 'translateX(2px)',
      },
      '96%': {
        transform: 'translateX(-2px)',
      },
      '98%': {
        transform: 'translateX(1px)',
      },
      '100%': {
        transform: 'translateX(0px)',
      },
    },
  }));
  const cls = useStyles();

  const handleUnassignHangtag = async hangtagId => {
    try {
      const res = await unassignHangtag({ variables: { hangtagId } });
      if (res?.data?.update_hangtags?.affected_rows) {
        toast.success(`Hangtag has been unassigned from move #${move.id}`);
      }
    } catch (err) {
      console.error(`Failed to unassign hangtag:`, err);
      toast.error(`Failed to unassign hangtag!`);
    }
  };

  const driveActions = [
    {
      label: `Go to Drive Details`,
      handler: move => goToMoveDetails(move.id),
    },
    {
      label: `Go to Lane Details`,
      handler: move => goToLaneDetails(move.lane.id),
    },
    {},
    {
      label: `Edit Move`,
      handler: move => goToMoveDetails(move.id, true),
      disabled: move.settled,
    },
    // {
    //   label: `Manage Accessorials`,
    //   handler: move => handleManageAccessorialsModalOpen(move.id),
    //   disabled: move.settled,
    // },
    {
      label: `Unassign Hangtag`,
      handler: move => handleUnassignHangtag(move?.hangtags?.[0]?.id),
      hide: !hasHangtag,
    },
    {},
    {
      label: `Sync Move to Driver`,
      handler: move => handleSyncOneMove(move),
      disabled: !checkMoveNeedsSync(move),
    },
    {},
    {
      label: `Search Pickup Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.pickup?.address),
    },
    {
      label: `Search Delivery Address`,
      handler: move => searchLocationOnGoogleMaps(move?.lane?.delivery?.address),
    },
    {
      label: `Search Directions`,
      handler: move => searchLaneOnGoogleMaps(move?.lane?.pickup?.address, move?.lane?.delivery?.address),
    },
  ];

  const getDelayString = type => {
    let seconds = 0;

    if (type === `pickup`) {
      if (pickupDelay) seconds = pickupDelay;
      if (extraPickupDuration && extraPickupDuration > pickupDelay) seconds = extraPickupDuration;
    }
    if (type === `drive`) {
      if (driveDelay) seconds = driveDelay;
      if (extraDriveDuration && extraDriveDuration > driveDelay) seconds = extraDriveDuration;
    }
    if (type === `delivery`) {
      if (deliveryDelay) seconds = deliveryDelay;
      if (extraDeliveryDuration && extraDeliveryDuration > deliveryDelay) seconds = extraDeliveryDuration;
    }

    let minutes = Number(seconds / 60).toFixed(0);

    if (type === `pickup`) {
      if (isCompleted) return `Pickup ran ${minutes} minutes behind`;
      return `Pickup is running ${minutes} minutes behind`;
    }
    if (type === `drive`) {
      if (isCompleted) return `Drive ran ${minutes} minutes behind`;
      return `Drive is running ${minutes} minutes behind`;
    }
    if (type === `delivery`) {
      if (isCompleted) return `Delivery ran ${minutes} minutes behind`;
      return `Delivery is running ${minutes} minutes behind`;
    }
  };

  // Get info for the bottom of the move tile
  const getSubInfo = () => {
    // If move is concierge/loaner, show consumer info
    if (getDriveTypeFromMove(move) === `concierge` || getDriveTypeFromMove(move) === `loaner`) {
      return (
        <Tooltip
          title={
            <>
              {move.consumer_name ? <div>Consumer Name - {move.consumer_name}</div> : null}
              {move.consumer_phone ? <div>Consumer Phone - {getCleansedPhoneNumber(move.consumer_phone)}</div> : null}
            </>
          }
        >
          <div className={cls.anchorBox}>
            <Typography className={cls.anchorTxt}>{move.consumer_name || `Unknown Consumer`}</Typography>
          </div>
        </Tooltip>
      );
    }

    // Otherwise, show the customer info
    return (
      <Tooltip
        title={
          <>
            {customer.name ? <div>Customer Name - {customer.name}</div> : null}
            {customer.phone ? <div>Customer Phone - {getCleansedPhoneNumber(customer.phone)}</div> : null}
            {customer.email ? <div>Customer Email - {customer.email}</div> : null}
          </>
        }
      >
        <div className={cls.anchorBox}>
          <Typography className={cls.anchorTxt}>{customer.name || `Unknown Customer`}</Typography>
        </div>
      </Tooltip>
    );
  };

  // Get the opacity for the move tile when viewing at-risk moves
  // Moves that are "at-risk" (less than 2 hours from deadline) will have normal opacity and all others will be faded
  const getMoveOpacity = () => {
    if (atRiskView) {
      if (isCompleted || isCanceled || isFailed || isRescheduled || !deadlineMs || deadlineMs > 7200000) return 0.1;
    }
    return 1;
  };

  return (
    <>
      <ContextMenuTrigger id={`drive-tile-${move.id}-cm`} source={`drive-tile-${move.id}-cm`}>
        <div className={cls.shell} style={{ opacity: getMoveOpacity() }}>
          {/* STATUS BADGE */}
          <MoveTileStatusBar enrichedMove={enrichedDrive} />

          {/* HANGTAG */}
          {hasHangtag && move?.hangtags?.[0]?.type === `yard` ? (
            <MoveTileHangtag hangtag={move?.hangtags?.[0]} parent={`move-tile`} />
          ) : null}

          {/* PAST OVERLAY */}
          {move.settled || isCompleted || isCanceled || isFailed || isRescheduled ? (
            <div className={cls.overlayPast} />
          ) : null}

          {/* PENDING OVERLAY */}
          {isDeclined || move.cancel_status === `pending` ? <div className={cls.overlayPending} /> : null}

          {/* TILE */}
          <div className={cls.tile}>
            {/* TEXT OVERLAY */}
            {move.settled ? (
              <Tooltip title='This move has been settled.'>
                <Typography className={cls.overlayTxt}>SETTLED</Typography>
              </Tooltip>
            ) : null}
            {isCanceled ? (
              <Tooltip title='This move was canceled after it was started.'>
                <Typography className={cls.overlayTxt}>CANCELED</Typography>
              </Tooltip>
            ) : null}
            {isRescheduled ? (
              <Tooltip title='This move was rescheduled.'>
                <Typography className={cls.overlayTxt}>RESCHEDULED</Typography>
              </Tooltip>
            ) : null}
            {isFailed ? (
              <Tooltip title='This move has failed.'>
                <Typography className={cls.overlayTxt}>FAILED</Typography>
              </Tooltip>
            ) : null}
            {isDeclined ? (
              <Tooltip title='This move was declined by the driver.'>
                <Typography className={cls.overlayTxt}>DECLINED</Typography>
              </Tooltip>
            ) : null}
            {move.cancel_status === `pending` ? (
              <Tooltip title='This pending canceled move requires an action!'>
                <Typography className={cls.overlayTxt}>PENDING</Typography>
              </Tooltip>
            ) : null}

            {/* EXTRA PICKUP */}
            {pickupDelay > 0 ? (
              <Tooltip title={getDelayString(`pickup`)} placement='top'>
                <div className={cls.pickupExtra}>
                  <div className={cls.extraBox}>
                    <Typography className={cls.extraTxt}>PICKUP</Typography>
                  </div>
                </div>
              </Tooltip>
            ) : null}

            {/* DRIVE MOVE */}
            <div className={cls.drive}>
              <Tooltip title={<MoveTileTooltip enrichedMove={enrichedDrive} />} classes={{ tooltip: cls.tooltip }}>
                <div className={cls.laneBox}>
                  <Typography className={cls.laneTxt}>
                    {enableMoveIds ? <strong>#{move.id} - </strong> : null}
                    {pickup.name || `Unknown Pickup`}
                  </Typography>
                  <div className={cls.laneBreak} />
                  <Typography className={cls.laneTxt}>to {delivery.name || `Unknown Delivery`}</Typography>
                </div>
              </Tooltip>
              <div className={cls.laneFade} />

              {getSubInfo()}

              {hasTags &&
              !move.settled &&
              move.cancel_status !== `pending` &&
              move.cancel_status !== `started` &&
              move.driver_status !== `declined` ? (
                <div className={cls.tagBox}>
                  <div className={cls.tagFade} />
                  <div className={cls.tags}>
                    {hasHangtag ? (
                      <MoveTileHangtagTooltip hangtag={move?.hangtags?.[0]}>
                        <Icon className={cls.tagIcon}>sell</Icon>
                      </MoveTileHangtagTooltip>
                    ) : null}
                    {priorHangtags && !hasHangtag ? (
                      <Tooltip title={<div>Prior Hangtags: {priorHangtags}</div>}>
                        <LocalOfferOutlinedIcon fontSize='small' />
                      </Tooltip>
                    ) : null}
                    {isTriangle ? (
                      <Tooltip title={<div>Triangle Move</div>}>
                        <div className={cls.triangle}>▲</div>
                      </Tooltip>
                    ) : null}
                    {checkForGroupableTag(move) ? (
                      <MoveTagGroupableTooltip move={move}>
                        <Icon className={cls.tagIcon}>hub</Icon>
                      </MoveTagGroupableTooltip>
                    ) : null}
                    {hasOpsTag ? (
                      <Tooltip title={<div>Ops Vehicle - {getFormattedVehicleFromMove(move)}</div>}>
                        <Icon className={cls.tagIcon}>drive_eta</Icon>
                      </Tooltip>
                    ) : null}
                    {hasConciergeTag ? (
                      <Tooltip title={<div>Concierge Vehicle - {getFormattedVehicleFromMove(move)}</div>}>
                        <Icon className={cls.tagIcon}>person</Icon>
                      </Tooltip>
                    ) : null}
                    {hasLoanerTag ? (
                      <Tooltip title={<div>Loaner Vehicle - {getFormattedVehicleFromMove(move)}</div>}>
                        <Icon className={cls.tagIcon}>emoji_transportation</Icon>
                      </Tooltip>
                    ) : null}
                    {hasManualTag ? (
                      <Tooltip title='Manual Transmission'>
                        <ManualIcon className={cls.tagIcon} />
                      </Tooltip>
                    ) : null}
                    {hasRailyardTag ? (
                      <Tooltip title={`Railyard Move`}>
                        <Icon className={cls.tagIcon}>calendar_view_week</Icon>
                      </Tooltip>
                    ) : null}
                    {hasSlaTag ? (
                      <DeadlineTooltip move={move} enrichedMove={enrichedDrive} deadlineMs={deadlineMs} calc='timeline'>
                        <Icon className={deadlineMs < 0 ? cls.tagIconShake : cls.tagIcon}>timelapse</Icon>
                      </DeadlineTooltip>
                    ) : null}
                    {hasNotesTag ? (
                      <Tooltip title={`Notes/Instructions - ${move.move_details}`}>
                        <Icon className={cls.tagIcon}>assignment</Icon>
                      </Tooltip>
                    ) : null}
                    {hasMoreTag ? (
                      <Tooltip title={`Tags - ${move.tags}`}>
                        <Icon className={cls.tagIcon}>more</Icon>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>

            {/* EXTRA DRIVE */}
            {driveDelay > 0 ? (
              <Tooltip title={getDelayString(`drive`)} placement='top'>
                <div className={cls.driveExtra}>
                  <div className={cls.extraBox}>
                    <Typography className={cls.extraTxt}>DRIVE</Typography>
                  </div>
                </div>
              </Tooltip>
            ) : null}

            {/* EXTRA DELIVERY */}
            {deliveryDelay > 0 ? (
              <Tooltip title={getDelayString(`delivery`)} placement='top'>
                <div className={cls.deliveryExtra}>
                  <div className={cls.extraBox}>
                    <Typography className={cls.extraTxt}>DELIVERY</Typography>
                  </div>
                </div>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </ContextMenuTrigger>

      <ContextMenu id={`drive-tile-${move.id}-cm`}>
        {driveActions.map((action, i) => {
          if (!action || action.hide) return null;
          if (!action.handler) return <Divider key={`drive-tile-action-${i}`} className={cls.divider} />;
          return (
            <ContextMenuItem
              key={`drive-tile-action-${i}`}
              disabled={action.disabled}
              onClick={() => action.handler(move)}
            >
              {action.label}
            </ContextMenuItem>
          );
        })}
      </ContextMenu>
    </>
  );
}
