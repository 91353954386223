import React, { useEffect } from 'react';
import { makeStyles, Grid, Typography, TextField, Chip, Container, InputAdornment, Icon } from '@material-ui/core';
import { useUserDetails} from '../../providers/UserDetailsProvider'
import { Spacer } from '@hopdrive/storybook';
import ReusableMultiselect from '../../components/ReusableMultiselect/ReusableMultiselect';
import AvatarUpload from '../../components/UserManagement/AvatarUpload';

const titleGridWidth = 5;

//////////////////////// COMPONENT ////////////////////////

export default function UserInfo({ user = {} }) {
  const cls = useStyles();
  const ctx = useUserDetails();

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };

  const handleSelectedRegions = selections => {
    ctx.setAllowedRegions(selections);
  };

  const handleRemoveRegion = index => {
    ctx.setAllowedRegions(prevRegions => {
      return prevRegions.filter((_, i) => i !== index);
    });
    ctx.setAllowedRegionIds(prevIds => {
      const updatedIds = [...prevIds];
      updatedIds.splice(index, 1);
      return updatedIds;
    });
  }

  return (
    <Container maxWidth='lg'>

     {/* VITALS */}
     <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Display Name <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              required
              fullWidth
              multiline
              label='Display Name'
              placeholder='Enter display name...'
              variant='outlined'
              size='small'
              value={ctx.displayName}
              onChange={handleInputChange(ctx.setDisplayName)}
              error={ctx?.validation?.displayName === false}
              helperText={ctx?.validation?.displayName === false ? `A display name is required!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      person
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

     <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Email <span className={cls.sectionRequiredTxt}>*</span>
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              required
              fullWidth
              multiline
              label='Email'
              placeholder='Enter email address...'
              variant='outlined'
              size='small'
              value={ctx.email}
              onChange={handleInputChange(ctx.setEmail)}
              error={ctx?.validation?.email === false}
              helperText={ctx?.validation?.email === false ? `A valid email address is required!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      mail
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Phone (Optional)
            </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <TextField
              fullWidth
              multiline
              label='Phone'
              placeholder='Enter phone number...'
              variant='outlined'
              size='small'
              value={ctx.phone}
              onChange={handleInputChange(ctx.setPhone)}
              error={ctx?.validation?.phone === false}
              helperText={ctx?.validation?.phone === false ? `Phone number is invalid!` : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                    <Icon color='disabled' fontSize='small'>
                      phone
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container wrap='nowrap'>
        <Grid item xs={titleGridWidth}>
          <div className={cls.sectionL}>
            <Typography className={cls.sectionTitleTxt}>
              Avatar (Optional)
            </Typography>
            <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
              Click or drag and drop a PNG or SVG file containing an imge of the user. Due to server limitations, the maximum file size is 0.5MB/500KB.
              </Typography>
          </div>
        </Grid>

        <Grid item>
          <div className={cls.sectionLine} />
        </Grid>

        <Grid item xs>
          <div className={cls.sectionR}>
            <AvatarUpload 
              avatar={ctx.avatar}
              setAvatar={ctx.setAvatar}
            />
          </div>
        </Grid>
      </Grid>

      {/* ALLOWED REGIONS */}

        <Grid container wrap='nowrap'>
          <Grid item xs={titleGridWidth}>
            <div className={cls.sectionL}>
              <Typography className={cls.sectionTitleTxt}>
                Allowed Regions <span className={cls.sectionRequiredTxt}>*</span>
              </Typography>
              <Spacer />
              <Typography className={cls.sectionSubtitleTxt}>
                Hopdrive users must be given permissions for at least one market region.
              </Typography>
            </div>
          </Grid>

          <Grid item>
            <div className={cls.sectionLine} />
          </Grid>

          <Grid item xs>
            <div className={cls.sectionR}>
              <ReusableMultiselect
                dataArray={ctx.regions}
                selectedArray={ctx.allowedRegionIds}
                optionName={'name'}
                label={'Allowed Region'}
                handleOutput={handleSelectedRegions}
              />

              <Spacer />
              {ctx?.allowedRegions && ctx.allowedRegions?.map((region, index) => (
                <Chip
                  key={index}
                  style={{margin: 5}}
                  label={region.name}
                  onDelete={() => handleRemoveRegion(index)}
                />
              ))}
            </div>
          </Grid>
        </Grid>
    </Container>

  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  sectionL: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionR: {
    position: 'relative',
    padding: theme.spacing(5, 4, 5, 4),
  },
  sectionTitleTxt: {
    fontSize: 16,
    fontWeight: 500,
  },
  sectionRequiredTxt: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.error.main,
  },
  sectionSubtitleTxt: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  sectionLine: {
    width: 1,
    height: '100%',
    background: theme.palette.divider,
  },
  titleChipIcon: {
    fontSize: 20,
    margin: theme.spacing(0, 0.5, 0, 0),
  },
}));