//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import * as Sentry from '@sentry/react';
import { useMoves } from './useMoves';
import Toolbar from '../../components/Toolbar';
import { MovesFilter, TableWrapper } from './index';
import MovesToPrint from './MovesToPrint';
import { useLocation } from 'react-router-dom';

//////////////////////// COMPONENT ////////////////////////

export default function Moves() {
  const cls = useStyles();
  const location = useLocation();

  // Call the useMoves hook to get additional logic
  const {
    defaultRange,
    defaultStart,
    defaultEnd,
    defaultCustomerIdArray,
    defaultMoveStatusArray,
    defaultCancelStatusArray,
    getDefaultDisable,
    getDefaultRange,
    getDefaultStart,
    getDefaultEnd,
    getDefaultCustomerIdArray,
    getDefaultMoveStatusArray,
    getDefaultCancelStatusArray,
    generateCSV,
  } = useMoves();

  // Component state
  const [disablePickers, setDisablePickers] = React.useState(getDefaultDisable());
  const [range, setRange] = React.useState(getDefaultRange());
  const [start, setStart] = React.useState(getDefaultStart());
  const [end, setEnd] = React.useState(getDefaultEnd());
  const [search, setSearch] = React.useState(``);
  const [customerIdArray, setCustomerIdArray] = React.useState(getDefaultCustomerIdArray());
  const [moveStatusArray, setMoveStatusArray] = React.useState(getDefaultMoveStatusArray());
  const [cancelStatusArray, setCancelStatusArray] = React.useState(getDefaultCancelStatusArray());
  const [checkedMoves, setCheckedMoves] = React.useState([]);
  const [moves, setMoves] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({
    orderBy: 'id',
    limit: 50,
    order: 'desc',
    offset: 0,
  });
  const [searchChange, setSearchChange] = React.useState(false);
  const [statusChange, setStatusChange] = React.useState(false);
  const [cancelStatusChange, setCancelStatusChange] = React.useState(false);
  const [customerChange, setCustomerChange] = React.useState(false);
  // Whenever certain filters change, reset checked moves
  React.useEffect(() => {
    setCheckedMoves([]);
  }, []);
  // Set status filter to pending if pendingCanceledMoves is true
  React.useEffect(() => {
    if (location.state?.pendingCancel) {
      setCancelStatusArray([{ id: 1, label: 'Pending Cancel', value: 'pending' }]);
    }
  }, [location]);

  // Handle clearing the filters stored in state
  const handleClearFilters = () => {
    localStorage.removeItem(`moves-index-range`);
    localStorage.removeItem(`moves-index-start`);
    localStorage.removeItem(`moves-index-end`);

    setRange(defaultRange);
    setStart(defaultStart);
    setEnd(defaultEnd);
    setSearch(``);
    setSearchChange(true);
    setCustomerIdArray(defaultCustomerIdArray);
    setCustomerChange(true);
    setMoveStatusArray(defaultMoveStatusArray);
    setStatusChange(true);
    setCancelStatusArray(defaultCancelStatusArray);
    setCancelStatusChange(true);
  };

  // Control range picker
  const handleRangeChange = value => {
    localStorage.setItem(`move-index-range`, value);
    if (value === `day`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf(`day`)).format());
      setEnd(dayjs.utc(dayjs().endOf(`day`)).format());
    } else if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf(`day`).subtract(1, value)).format());
      setEnd(dayjs.utc(dayjs().endOf(`day`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`move-index-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).endOf(`day`)).format();
      localStorage.setItem(`move-index-end`, newDate);
      setEnd(newDate);
    }
  };

  // Control customer filter
  const handleCustomerIdsChange = (customerIdArray = []) => {
    if (customerIdArray) setCustomerIdArray(customerIdArray);
    setCustomerChange(true);
  };

  // Control move status filter
  const handleStatusesChange = (moveStatusArray = []) => {
    if (moveStatusArray) setMoveStatusArray(moveStatusArray);
    setStatusChange(true);
  };

  const handleCancelStatusesChange = (cancelStatusArray = []) => {
    if (cancelStatusArray) setCancelStatusArray(cancelStatusArray);
    setCancelStatusChange(true);
  };

  const tableActions = [
    {
      label: `Generate CSV`,
      handler: () => generateCSV(checkedMoves, moves, start, end),
      disabled: !checkedMoves?.length && !moves?.length,
    },
    {
      component: () => (
        <MovesToPrint
          moves={moves}
          checkedMoves={checkedMoves}
          action={{
            disabled: !checkedMoves?.length && !moves?.length,
          }}
        />
      ),
    },
  ];

  return (
    <div className={cls.root}>
      <Toolbar fullscreen title='Moves' onClear={handleClearFilters} actions={tableActions}>
        <MovesFilter
          moves={moves}
          range={range}
          start={start}
          end={end}
          search={search}
          customersArray={customerIdArray}
          moveStatusArray={moveStatusArray}
          cancelStatusArray={cancelStatusArray}
          disablePickers={disablePickers}
          onRangeChange={handleRangeChange}
          onDateChange={handleDateChange}
          onSearchChange={setSearch}
          onCustomerIdsChange={handleCustomerIdsChange}
          onStatusesChange={handleStatusesChange}
          onCancelStatusesChange={handleCancelStatusesChange}
          checkedMoves={checkedMoves}
        />
      </Toolbar>
      {/* If table body has an error, show error message instead of table */}
      <Sentry.ErrorBoundary fallback={<DefaultErrorFallback message='ERROR DISPLAYING MOVES TABLE' />}>
        <TableWrapper
          checkedMoves={checkedMoves}
          setCheckedMoves={setCheckedMoves}
          customerIdArray={customerIdArray}
          customerChange={customerChange}
          setCustomerChange={setCustomerChange}
          moveStatusArray={moveStatusArray}
          statusChange={statusChange}
          setStatusChange={setStatusChange}
          cancelStatusArray={cancelStatusArray}
          cancelStatusChange={cancelStatusChange}
          setCancelStatusChange={setCancelStatusChange}
          start={start}
          end={end}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          search={search}
          searchChange={searchChange}
          setSearchChange={setSearchChange}
          moves={moves}
          setMoves={setMoves}
        />
      </Sentry.ErrorBoundary>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(7),
  },
}));
