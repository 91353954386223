import React, { createContext, useContext } from 'react';
import { useSubscription } from '@apollo/client';
import { queries } from '../utils/queries';
import { useProviders } from '../../../hooks/useProviders';
import { useSettings } from './SettingsProvider';
import { useData } from '../../../providers/DataProvider';
import { SimpleLogger } from '../../../utils/SimpleLogger';

// Unassigned Move Notification Sound
import { Howl } from 'howler';
import beepbeep from '../../../static/beepbeep.mp3';
import dayjs from 'dayjs';
const newMoveSound = new Howl({
  src: [beepbeep],
  volume: 0.5,
});

const UnassignedMovesContext = createContext({});

let _unassignedMoves = [];
let _unassignedMovesWithDetails = [];
let _state = `subscription.loading`;
let _queryRunning = false;

function UnassignedMovesProvider({ children }) {
  const ctx = useData();

  const { getSubscriberProviderState } = useProviders();
  const { timelineDate, enableUnassignedMovesLogs } = useSettings();

  const [unassignedMoves, setUnassignedMoves] = React.useState([]);

  const { log } = new SimpleLogger({ prefix: 'UnassignedMovesProvider', enabled: enableUnassignedMovesLogs });

  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData,
  } = useSubscription(queries.SUBSCRIBE_TO_UNASSIGNED_MOVES_CHANGES, {
    fetchPolicy: 'network-only',
  });

  _state = getSubscriberProviderState({
    subscriptionLoading,
    subscriptionError,
    subscriptionData,
    queryRunning: _queryRunning,
  });

  log('current _state', _state);
  log('current _state values', {
    subscriptionLoading,
    subscriptionError,
    subscriptionData,
    queryRunning: _queryRunning,
  });

  const handleSubscriptionSuccess = async () => {
    const { moves: heartbeatUnassignedMoves } = subscriptionData || {};
    const inboundUnassignedMoves = heartbeatUnassignedMoves;

    // Check if we found any change in the unassigned move ids list
    // Also check if the subscription has returned a different number of moves than we have stored in state
    if (
      JSON.stringify(_unassignedMoves.sort()) !== JSON.stringify(inboundUnassignedMoves.sort()) ||
      unassignedMoves.length !== _unassignedMoves.length
    ) {
      if (inboundUnassignedMoves.length > _unassignedMoves.length) newMoveSound.play();
      _unassignedMoves = inboundUnassignedMoves || [];
      log(`📋 Subscription detected changes. Running query...`);
      _state = 'query.loading';
      const moveRes = await ctx.apolloClient.query({
        query: queries.GET_UNASSIGNED_MOVE_DETAILS,
        fetchPolicy: 'network-only',
      });
      if (moveRes.data && moveRes.data.moves) {
        _unassignedMovesWithDetails = Object.assign([], moveRes.data.moves);
        setUnassignedMoves(_unassignedMovesWithDetails);
        log(`📋 Providing ${moveRes.data.moves.length} unassigned move(s)`);
        moveRes.data.moves.map(m => log(`   🧽 Move ${m.id}`));
        _state = 'query.successful';
      }
      if (moveRes.errors) {
        console.error('Error Querying Unassigned Moves:', moveRes.errors[0]);
        _state = 'query.error';
      }
    } else {
      log(
        `📋 Subscription ran successfully but no changes in data detected`,
        _unassignedMoves,
        inboundUnassignedMoves
      );
      _state = 'query.successful';
    }
  };

  switch (_state) {
    case 'subscription.loading':
      log(`Subscription is still loading...`);
      break;
    case 'subscription.error':
      log(`Subscription errored out!`);
      break;
    case 'subscription.successful':
      handleSubscriptionSuccess();
      break;
    case 'query.successful':
      log(`Successfully queried Unassigned Moves`);
      break;
    case 'query.error':
      log(`Error querying Unassigned Moves`);
      break;
    case 'query.loading':
      log(`Query is loading...`);
      break;
    default:
      break;
  }

  const getUnassignedMovesLength = () => {
    if (unassignedMoves && unassignedMoves.length > 0) {
      const filteredMoves = unassignedMoves.filter(
        um => dayjs(um.ready_by).format(`YYYY-MM-DD`) === timelineDate.format(`YYYY-MM-DD`)
      );
      return filteredMoves.length;
    }
    return 0;
  };

  // Export state variables by adding them to context
  const context = {
    unassignedMoves,
    state: _state,
    unassignedMovesCount: getUnassignedMovesLength(),
    queryRunning: _queryRunning,
  };

  return <UnassignedMovesContext.Provider value={context}>{children}</UnassignedMovesContext.Provider>;
}

const useUnassignedMoves = () => useContext(UnassignedMovesContext);

export { useUnassignedMoves, UnassignedMovesProvider };
