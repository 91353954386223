//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { toast } from 'react-toastify';
import { useSubscription, useMutation } from '@apollo/client';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { DriversProvider } from '../Plans-New/providers/DriversProvider';
import sdk from '@hopdrive/sdk';
import {
  makeStyles,
  useTheme,
  Container,
  Grid,
  Typography,
  IconButton,
  Icon,
  Tooltip,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Divider,
} from '@material-ui/core';
import { Button, Divide, Spacer } from '@hopdrive/storybook';

import * as Sentry from '@sentry/react';

import Loading from '../../components/Loading';
import DefaultErrorFallback from '../../components/Fallbacks/DefaultErrorFallback';
import DefaultEmptyFallback from '../../components/Fallbacks/DefaultEmptyFallback';
import MoveOutcome from '../../components/MoveOutcome/MoveOutcome';
import MoveLocation from '../../components/MoveLocation.js'

import ImageCarousel from '../../components/ImageCarousel';
import MoveStatusTracker from '../../components/MoveStatusTracker';

import { getUserRole, getUserEmail } from '../../utils/authHelper';

import {
  GET_MOVE_DETAILS,
  UPDATE_TIMES_MOVE_DETAILS,
  UPDATE_STATUSES_MOVE_DETAILS,
  SAVE_MOVE_DETAILS,
  UPDATE_MOVE_CANCEL,
  UPDATE_LOCATION_REGION,
  UPDATE_HANGTAG,
} from './gql';

import {
  MoveAccessorials,
  MoveAP,
  MoveAR,
  MoveAuditTimeline,
  MoveBreakdown,
  MoveInfo,
  MoveLane,
  MoveRelated,
  MoveStatusModal,
  MoveTimeModal,
  MoveTracking,
  VinOverrideModal,
} from './index';

import ARReviewModal from '../../components/ARReview/ARReviewModal';
import ARLogsModal from '../../components/ARLogsModal';
import ARPriceOverrideModal from '../../components/ARPriceOverrideModal/ARPriceOverrideModal';
// import ManageAccessorialsModal from '../../components/ManageAccessorialsModal/ManageAccessorialsModal';
import SpendAuthorizationModal from '../../components/SpendAuthorizationModal';
import AuthorizationWindowModal from '../../components/AuthorizationWindowModal';
// import CancelMoveModal from '../../components/CancelMoveModal';
import EditLaneModal from '../../components/EditLaneModal/EditLaneModal';

import { useEngine } from '../../hooks/useEngine';
import { useTools } from '../../hooks/useTools';
import { useMoveDetails } from './useMoveDetails';
import TrackingLinkModal from './TrackingLinkModal';
import HangtagManagementModal from './HangtagManagementModal';
import { useVehiclePhotos } from '../../hooks/useVehiclePhotos';

const axios = require('axios');
const { CON_SD } = require('../../utils/env');

//////////////////////// COMPONENT ////////////////////////

function MoveDetails(props) {
  // Hooks
  const cls = useStyles();
  const theme = useTheme();
  const { rerunAR, rerunAP, tagDeliveryReported } = useEngine();
  const {
    goToPreviousPage,
    goToLaneDetails,
    goToLocationDetails,
    goToInvoice,
    goToMovePlanner,
    copyToClipboard,
    getPendingAccessorial,
  } = useTools();
  const { resetSwipeAttempts, expirePendingAccessorial } = useMoveDetails();

  const log = false;

  // Get local moveId
  const moveId = props.match.params.id;

  // Queries, subscriptions & mutations
  const { loading, error, data } = useSubscription(GET_MOVE_DETAILS, {
    variables: {
      id: moveId,
    },
  });
  const [updateTimes] = useMutation(UPDATE_TIMES_MOVE_DETAILS);
  const [updateStatuses] = useMutation(UPDATE_STATUSES_MOVE_DETAILS);
  const [updateMove] = useMutation(SAVE_MOVE_DETAILS);
  const [updateMoveCancel] = useMutation(UPDATE_MOVE_CANCEL);

  const [editMode, setEditMode] = React.useState(
    props.location && props.location.state && props.location.state.editMode
  );
  const [actionsOpen, setActionsOpen] = React.useState(null);
  const [originalRegionId, setOriginalRegionId] = React.useState(null);
  const [pickupPhotos, setPickupPhotos] = React.useState(null);
  const [deliveryPhotos, setDeliveryPhotos] = React.useState(null);
  const [fuelPhotos, setFuelPhotos] = React.useState(null);
  const [mainImageUrl, setMainImageUrl] = React.useState();
  const [userRole, setUserRole] = React.useState();
  const [userEmail, setUserEmail] = React.useState();

  const [editLaneModalOpen, setEditLaneModalOpen] = React.useState(false);
  const handleEditLaneModalClose = () => {
    setEditLaneModalOpen(false);
  };
  const handleEditLaneModalOpen = () => {
    setEditLaneModalOpen(true);
  }
  const { getCarouselImagesArray } = useVehiclePhotos();
  const [imageSelection, setImageSelection] = React.useState(`pickup`);
  const getImageSelection = () => {
    if (imageSelection === `pickup`) return pickupPhotos;
    if (imageSelection === `delivery`) return deliveryPhotos;
    if (imageSelection === `fuel`) return fuelPhotos;
  };

  React.useEffect(() => {
    const getRole = async (user = null) => {
      let userRole = await getUserRole(user);
      setUserRole(userRole);
    };

    let email = getUserEmail();
    setUserEmail(email);
    getRole();
  }, []);

  React.useEffect(() => {
    if (data && data.moves[0].lane.pickup.region && data.moves[0].lane.pickup.region.id) {
      setOriginalRegionId(data.moves[0].lane.pickup.region.id);
    } else if (data && data.moves[0].lane.delivery.region && data.moves[0].lane.delivery.region.id) {
      setOriginalRegionId(data.moves[0].lane.delivery.region.id);
    } else setOriginalRegionId(null);

    let move = {};
    if (data && data.moves && Array.isArray(data.moves) && data.moves.length > 0) {
      move = data.moves[0];
    } else return;

    const pickupSignerName = move && move.pickup_workflow_data && move.pickup_workflow_data['signer-name'];
    const deliverySignerName = move && move.delivery_workflow_data && move.delivery_workflow_data['signer-name'];

    // If we know we have photos in our own database then lets load from there
    if (move?.pickup_photos?.length || move?.delivery_photos?.length) {
      let tempPickupPhotos = getCarouselImagesArray({ move: move, type: `pickup`, signer: pickupSignerName });
      let tempDeliveryPhotos = getCarouselImagesArray({ move: move, type: `delivery`, signer: deliverySignerName });
      let tempFuelPhotos = getCarouselImagesArray({ move: move, type: `fuel` });

      // Set the main image url for moveinfo component
      try {
        const url = tempPickupPhotos?.find(photo => photo.id === `driver-front`)?.original;
        setMainImageUrl(url);
      } catch (error) {
        console.error('Failed to set the main image URL:', error);
      }

      setPickupPhotos(tempPickupPhotos);
      setDeliveryPhotos(tempDeliveryPhotos);
      setFuelPhotos(tempFuelPhotos);
    }
  }, [data]);

  // START MODALS //

  const [timeModal, setTimeModal] = React.useState({ open: false });
  const handleTimeModalOpen = (input = null) => {
    setTimeModal({ open: true, input: input });
  };
  const handleTimeModalClose = (output = null) => {
    setTimeModal({ ...timeModal, open: false });
    if (output) handleTimeChanges(output.move, output);
  };
  const handleTimeEdit = move => {
    handleTimeModalOpen({
      move: move,
      laneDuration: getPropValue(move, `lane.duration_sec`) || 0,
      readyTime: move.ready_by,
      pickupTime: move.pickup_time,
    });
  };

  const [statusModal, setStatusModal] = React.useState({ open: false });
  const handleStatusModalOpen = (input = null) => {
    setStatusModal({ open: true, input: input });
  };
  const handleStatusModalClose = (output = null) => {
    setStatusModal({ ...statusModal, open: false });
    if (output) handleStatusChanges(output.move, output);
  };
  const handleStatusEdit = move => {
    handleStatusModalOpen({
      move: move,
      moveType: move.move_type,
      status: move.status,
      cancelStatus: move.cancel_status,
    });
  };

  const [arReviewModal, setARReviewModal] = React.useState({ open: false });
  const handleARReviewModalOpen = (armove = null) => {
    setARReviewModal({
      ...arReviewModal,
      open: true,
      input: armove,
    });
  };
  const handleARReviewModalClose = (output = null) => {
    if (output) setARReviewModal({ ...arReviewModal, open: false, output: output });
    else setARReviewModal({ ...arReviewModal, open: false });
  };

  const [arLogsModal, setARLogsModal] = React.useState({ open: false });
  const handleARLogsModalOpen = (type = null, events = null) => {
    setARLogsModal({
      ...arLogsModal,
      open: true,
      input: {
        type: type,
        events: events,
      },
    });
  };
  const handleARLogsModalClose = (output = null) => {
    if (output) setARLogsModal({ ...arLogsModal, open: false, output: output });
    else setARLogsModal({ ...arLogsModal, open: false });
  };

  const [arPriceOverrideModal, setARPriceOverrideModal] = React.useState({ open: false });
  const handleARPriceOverrideModalOpen = moveId => {
    setARPriceOverrideModal({
      ...arPriceOverrideModal,
      open: true,
      input: {
        moveId: moveId,
        priceOverride: move?.price_override || null,
      },
    });
  };
  const handleARPriceOverrideModalClose = (output = null) => {
    if (output) setARPriceOverrideModal({ ...arPriceOverrideModal, open: false, output: output });
    else setARPriceOverrideModal({ ...arPriceOverrideModal, open: false });
  };

  const [moveOutcomeModal, setMoveOutcomeModal] = React.useState({ open: false });
  const handleMoveOutcomeModalOpen = input => {
    setMoveOutcomeModal({ ...moveOutcomeModal, open: true, input: input });
  };
  const handleMoveOutcomeModalClose = output => {
    setMoveOutcomeModal({ ...moveOutcomeModal, open: false, output: output });
  };

  // const [accessorialsModal, setAccessorialsModal] = React.useState(false);
  // const handleAccessorialsModalOpen = () => {
  //   setAccessorialsModal(true);
  // };
  // const handleAccessorialsModalClose = () => {
  //   setAccessorialsModal(false);
  // };

  const [spendAuthModal, setSpendAuthModal] = React.useState(false);
  const handleSpendAuthModalOpen = () => {
    setSpendAuthModal(true);
  };
  const handleSpendAuthModalClose = () => {
    setSpendAuthModal(false);
  };
  const [authWindowModal, setAuthWindowModal] = React.useState(false);
  const handleAuthWindowModalOpen = () => {
    setAuthWindowModal(true);
  };
  const handleAuthWindowModalClose = () => {
    setAuthWindowModal(false);
  };

  // const [cancelModal, setCancelModal] = React.useState(false);
  // const handleCancelModalOpen = () => {
  //   setCancelModal(true);
  // };
  // const handleCancelModalClose = async (output = null) => {
  //   if (moveId && output) {
  //     try {
  //       let variables = {};
  //       if (output.cancelStatus === 'canceled') {
  //         variables = {
  //           moveId: moveId,
  //           cancelStatus: output.cancelStatus,
  //           cancelReason: output.cancelReason,
  //           move_failed: null,
  //           payable: false,
  //           chargeable: false,
  //         };
  //       } else {
  //         variables = {
  //           moveId: moveId,
  //           cancelStatus: output.cancelStatus,
  //           cancelReason: output.cancelReason,
  //           move_failed: null,
  //           payable: true,
  //           chargeable: true,
  //         };
  //       }
  //       const res = await updateMoveCancel({ variables });
  //       if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
  //         toast.success(`Successfully updated move!`);
  //         setCancelModal(false);
  //       }
  //     } catch (err) {
  //       toast.error(`Failed to update move!`);
  //       console.error(`Failed to update move:`, err);
  //     }
  //   } else setCancelModal(false);
  // };

  const [updateHangTag] = useMutation(UPDATE_HANGTAG);
  const [hangtagManagementModal, setHangtagManagementModal] = React.useState(false);
  const handleHangtagManagementModalOpen = () => {
    setHangtagManagementModal(true);
  };
  const handleHangtagManagementModalClose = (output = null) => {
    setHangtagManagementModal(false);
    console.log('Closed hangtag modal', output);
    if (output) {
      const hangtag = move?.hangtags?.[0];
      updateHangTag({ variables: { id: hangtag?.id, updated_by: `admin`, ...output } });
      console.log('Closed hangtag modal', output, { variables: { id: hangtag?.id, updated_by: `admin`, ...output } });
      toast.info(`Saved hangtag with ${output?.driver_id} - ${output?.status} - ${output?.move_id}`);
    }
  };

  const [trackingLinkModal, setTrackingLinkModal] = React.useState(false);
  const handleTrackingLinkModalOpen = () => {
    setTrackingLinkModal(true);
    encodeIds();
  };
  const handleTrackingLinkModalClose = () => {
    setTrackingLinkModal(false);
  };
  const [conciergeUrl, setConciergeUrl] = React.useState('');
  const encodeIds = async () => {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/encodeHashIds',
        data: {
          moveId: move.id,
          type: 'concierge',
        },
      }).then(res => {
        log && console.log('Response:', res);
        if (res.status === 200) {
          log && console.log('Success!');
        } else {
          console.error('error');
        }
        setConciergeUrl(`https://${CON_SD}.hopdrive.io/${res.data}`);
      });
    } catch (err) {
      console.error(`Error hashing ID:`, err);
    }
  };

  const [vinOverrideModal, setVinOverrideModal] = React.useState(false);
  const handleVinOverrideModalOpen = () => {
    setVinOverrideModal(true);
    generateOverrideHash();
  };
  const handleVinOverrideModalClose = () => {
    setVinOverrideModal(false);
  };
  const [vinOverrideHash, setVinOverrideHash] = React.useState('');
  const generateOverrideHash = async () => {
    try {
      await axios({
        method: 'POST',
        url: '/.netlify/functions/getVinOverrideHash',
        data: {
          moveId: move.id,
          driverId: move.driver_id,
        },
      }).then(res => {
        log && console.log('Response:', res);
        if (res.status === 200) {
          log && console.log('Success!');
        } else {
          console.error('error');
        }
        setVinOverrideHash(res.data);
      });
    } catch (err) {
      console.error(`Error hashing VIN Override:`, err);
    }
  };

  // END MODALS //

  const [active, setActive] = React.useState(false);
  const [autoAssign, setAutoAssign] = React.useState(false);
  const [chargeable, setChargeable] = React.useState(false);
  const [color, setColor] = React.useState(``);
  const [consumerName, setConsumerName] = React.useState(``);
  const [consumerPhone, setConsumerPhone] = React.useState(``);
  const [consumerPickup, setConsumerPickup] = React.useState(false);
  const [dealerContact, setDealerContact] = React.useState(``);
  const [lyftFlag, setLyftFlag] = React.useState(false);
  const [make, setMake] = React.useState(``);
  const [manualFlag, setManualFlag] = React.useState(false);
  const [model, setModel] = React.useState(``);
  const [moveTemplate, setMoveTemplate] = React.useState(``);
  const [moveType, setMoveType] = React.useState(``);
  const [notes, setNotes] = React.useState(``);
  const [odometer, setOdometer] = React.useState(``);
  const [payable, setPayable] = React.useState(false);
  const [priority, setPriority] = React.useState(10);
  const [rateClass, setRateClass] = React.useState(``);
  const [rateClassOverride, setRateClassOverride] = React.useState(false);
  const [refNum, setRefNum] = React.useState(``);
  const [rideType, setRideType] = React.useState(``);
  const [stock, setStock] = React.useState(``);
  const [tags, setTags] = React.useState([]);
  const [vin, setVin] = React.useState(``);
  const [year, setYear] = React.useState(``);
  const [weight, setWeight] = React.useState(``);
  const [vehicleWeightMetadata, setVehicleWeightMetadata] = React.useState(null);
  const [config, setConfig] = React.useState(null);

  const handleEditMode = () => {
    if (editMode) {
      // toast.info(`Edit mode deactivated!`, { autoClose: 2000 });
      setEditMode(false);
    } else {
      // toast.info(`Edit mode activated!`, { autoClose: 2000 });
      if (!move.settled) setEditMode(true);
    }
  };

  // Go to the move planner with a duplicate move
  const handleDupeMove = (move, isInverse = false) => {
    goToMovePlanner({ move, customerId: move.customer.id, isInverse });
  };

  // Save changes in time modal
  const handleTimeChanges = async (move, output) => {
    try {
      const variables = {
        id: move.id,
        readyTime: output.readyTime || move.ready_by,
        pickupTime: output.pickupTime || move.pickup_time,
        deliveryTime: output.deliveryTime || move.delivery_time,
      };

      const res = await updateTimes({ variables });
      if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
        toast.success(`Successfully updated times!`);
      }
    } catch (err) {
      toast.error(`Failed to update times!`);
      console.error(`Failed to update times:`, err);
    }
  };

  // Save changes in status modal
  const handleStatusChanges = async (move, output) => {
    try {
      const variables = {
        id: move.id,
        status: output.status,
        pickupStarted: output.pickupStarted,
        pickupArrived: output.pickupArrived,
        pickupSuccessful: output.pickupSuccessful,
        deliveryStarted: output.deliveryStarted,
        deliveryArrived: output.deliveryArrived,
        deliverySuccessful: output.deliverySuccessful,
      };

      const res = await updateStatuses({ variables });
      if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
        toast.success(`Successfully updated statuses!`);
      }
    } catch (err) {
      toast.error(`Failed to update statuses!`);
      console.error(`Failed to update statuses:`, err);
    }
  };

  // Save changes out of edit mode
  const handleSaveChanges = async move => {
    try {
      if (move.settled) {
        toast.info('Move is settled. Cannot save changes!');
        console.log('Move is settled. Cannot save changes!');
        return;
      }

      const variables = {
        id: move.id,
        active: active ? 1 : 0,
        auto_assign: autoAssign ? 1 : 0,
        chargeable: chargeable || false,
        vehicle_color: color || null,
        consumer_name: consumerName,
        consumer_phone: consumerPhone,
        consumer_pickup: consumerPickup || false,
        dealer_contact: dealerContact || null,
        lyft_flag: lyftFlag ? 1 : 0,
        vehicle_make: make || null,
        manual_flag: manualFlag || false,
        vehicle_model: model || null,
        pickup_template_override: moveTemplate || null,
        move_type: moveType || null,
        move_details: notes || null,
        vehicle_odometer: odometer || null,
        payable: payable || false,
        priority: Number(priority),
        class: rateClass || null,
        rate_class_override: rateClassOverride ? 1 : 0,
        reference_num: refNum || null,
        ride_type: rideType || null,
        vehicle_stock: stock || null,
        tags: tags.length > 0 ? tags.join(`,`) : null,
        vehicle_vin: vin || null,
        vehicle_year: year || null,
        vehicle_gross_weight_lbs: Number(weight) || null,
        config: config || null,
      };

      const res = await updateMove({ variables });
      if (res && res.data && res.data.update_moves && res.data.update_moves.affected_rows > 0) {
        toast.success(`Successfully updated move!`);
        setEditMode(false);
        writeEventLog(variables, move, vehicleWeightMetadata);
      }
    } catch (err) {
      toast.error(`Failed to update move!`);
      console.error(`Failed to update move:`, err);
    }
  };

  const getMoveDetailsMetadata = (newObject, oldObject) => {
    let delta = {};
    for (var k in oldObject) {
      if (typeof oldObject[k] === 'object') continue;
      if (oldObject[k] === newObject[k]) continue;
      if (oldObject[k] && !newObject[k]) continue;
      delta[k] = { old: oldObject[k], new: newObject[k] };
    }
    return delta;
  };

  const writeEventLog = async (variables, move, vehicleWeightMetadata) => {
    try {
      let metadata = await getMoveDetailsMetadata(variables, move);
      if (metadata && vehicleWeightMetadata) {
        metadata['vehicle_weight'] = vehicleWeightMetadata;
      } else if (!metadata && vehicleWeightMetadata) {
        metadata = { vehicle_weight: vehicleWeightMetadata };
      }
      log && console.log('--EVENT LOG--');
      // Insert move update eventlog
      let eventConfig = {
        action: `move.details.updated`,
        user: userEmail,
        role: 'admin',
        move_id: move.id,
        customer_id: move.customer?.id,
        lane_id: move.lane_id,
        driver_id: move.driver_id,
        metadata: metadata,
      };
      let logRes = await sdk.events.buildAndCreate(eventConfig);
      log && console.log(`Successfully inserted eventlog ${logRes.id}`);
    } catch (err) {
      console.error(`Failed to insert eventlog:`, err);
    }
  };

  const [updateLocationRegion] = useMutation(UPDATE_LOCATION_REGION);

  const handleActionsOpen = event => {
    setActionsOpen(event.currentTarget);
  };
  const handleActionsClose = event => {
    setActionsOpen(null);
  };

  const handleAction = action => {
    handleActionsClose();
    if (action.handler) action.handler();
  };

  const handleAuthWindowModalSave = () => {
    toast.success(`Authorzation window saved!`);
  };

  // LOADING STATE //
  if (loading) return <Loading fixed />;

  // ERROR STATE //
  if (error) {
    toast.error(`Error fetching move details!`);
    console.error(`Error fetching move details:`, error);
    Sentry.captureException(error);
    return <DefaultErrorFallback message='ERROR FETCHING MOVE DETAILS' />;
  }

  // EMPTY STATE //
  if (!data || !data.moves || !data.moves.length > 0) return <DefaultEmptyFallback message='NO MOVE FOUND' />;

  // DATA STATE //
  const move = data.moves[0];
  const pickupName = getPropValue(move, `lane.pickup.name`);
  const deliveryName = getPropValue(move, `lane.delivery.name`);
  const relatedMoves =
    move && move.plan && move.plan.moves && move.plan.moves.length > 0
      ? move.plan.moves.filter(m => m.id !== move.id)
      : [];

  const hasActiveAuthorization = move.accessorials.some(acc => acc.status === 'pending' && acc.authorization);

  var newRegionId;

  const updateRegion = async () => {
    try {
      const variables = {
        id: move.lane.pickup.id,
        regionId: newRegionId,
      };
      const res = await updateLocationRegion({ variables });
      if (res && res.data && res.data.update_locations && res.data.update_locations.affected_rows > 0) {
      }
    } catch (err) {
      toast.error(`Failed to update region!`);
      console.error(`Failed to update location:`, err);
    }
  };

  const getRegionId = async () => {
    // Used to get region_id from Latitude & Longitude

    let coords;
    if (move.lane.pickup.longitude !== null && move.lane.pickup.latitude !== null) {
      coords = [move.lane.pickup.longitude, move.lane.pickup.latitude];
    } else if (
      move.lane.pickup.longitude === null &&
      move.lane.pickup.latitude === null &&
      move.lane.delivery.longitude !== null &&
      move.lane.delivery.latitude !== null
    ) {
      coords = [move.lane.delivery.longitude, move.lane.delivery.latitude];
    } else coords = null;
    let regionRes = await sdk.regions.getByCoords(coords);
    log && console.log(regionRes);
    if (regionRes.success && regionRes.data && regionRes.data.length > 0) {
      newRegionId = regionRes.data[0].id;
      toast.info(`Region: ${newRegionId}`);
    } else if (regionRes.success && regionRes.data && regionRes.data.length === 0) {
      toast.info(`Region already up to date`);
    } else {
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region';
      let regionError = new Error(errorMessage);
      console.error(regionError);
      toast.error('Failed to fetch region');
    }

    if (newRegionId !== originalRegionId) {
      updateRegion();
      log && console.log('updating region');
    } else log && console.log('region unchanged');

    return newRegionId;
  };

  // Set actions
  const globalActions = [
    {
      label: `Manage Times`,
      handler: () => handleTimeEdit(move),
      disabled:
        move.settled ||
        move.status === `delivery successful` ||
        move.status === `droppedOff` ||
        move.status === `canceled` ||
        move.status === `failed` ||
        move.cancel_status === `started` ||
        move.cancel_status === `delivered` ||
        move.cancel_status === `canceled` ||
        move.cancel_status === `rescheduled`,
    },
    {
      label: `Manage Status`,
      handler: () => handleStatusEdit(move),
      disabled: move.settled || !move.driver_id,
    },
    {
      label: `Manage Hangtag`,
      handler: () => handleHangtagManagementModalOpen(),
      disabled: !move?.hangtags?.[0]?.id > 0,
    },
    {
      label: `Manage Outcome`,
      handler: () => handleMoveOutcomeModalOpen({ move }),
      disabled: move.settled,
    },
    // {
    //   label: `Manage Cancel Status`,
    //   handler: () => handleCancelModalOpen(),
    //   disabled: move.settled,
    // },
    // {
    //   label: `Manage Accessorials`,
    //   handler: () => handleAccessorialsModalOpen(),
    //   disabled: move.settled,
    // },
    {},
    {
      label: `Authorize Spend`,
      handler: () => handleSpendAuthModalOpen(),
      disabled: move.settled,
      hide: hasActiveAuthorization,
    },
    {},
    {
      label: `Get VIN Override Code`,
      handler: () => handleVinOverrideModalOpen(),
      disabled: !move?.driver_id,
    },
    {},
    {
      label: `Edit Lane`,
      handler: () => handleEditLaneModalOpen(),
      disabled: move.driver_id,
    },
    {},
    {
      label: `Create Duplicate Move`,
      handler: () => handleDupeMove(move, false),
    },
    {
      label: `Create Inverse Move`,
      handler: () => handleDupeMove(move, true),
    },
    {},
    {
      label: `View Tracking Link`,
      handler: () => handleTrackingLinkModalOpen(),
      disabled: !move.consumer_pickup,
    },
    {
      label: `Copy Delivery Timestamp`,
      handler: () => copyToClipboard(move.delivery_successful),
      hide: !move.delivery_successful,
    },
    {
      label: `Tag Delivery Reported`,
      handler: () => tagDeliveryReported(move),
      hide: (move.tags && move.tags.includes(`delivery reported`)) || move.customer.id !== 2,
    },
    {
      label: `Untag Delivery Reported`,
      handler: () => tagDeliveryReported(move),
      hide: !move.tags || !move.tags.includes(`delivery reported`),
    },
  ];

  const laneActions = [
    { label: `View Lane Details`, handler: () => goToLaneDetails(move.lane.id) },
    { label: `View Pickup Details`, handler: () => goToLocationDetails(move.lane.pickup.id) },
    { label: `View Delivery Details`, handler: () => goToLocationDetails(move.lane.delivery.id) },
    { label: `Refetch Region`, handler: () => getRegionId() },
    { label: `Edit Lane`, handler: () => handleEditLaneModalOpen(), disabled: move.driver_id },
  ];
  const accessorialActions = [
    // {
    //   label: `Manage Accessorials`,
    //   handler: () => handleAccessorialsModalOpen(),
    //   disabled: move.settled,
    // },
    {
      label: `Authorize Spend`,
      handler: () => handleSpendAuthModalOpen(),
      disabled: move.settled,
      hide: hasActiveAuthorization,
    },
    {
      label: `Set Valid Window`,
      handler: () => handleAuthWindowModalOpen(),
      disabled: move.settled,
      hide: !hasActiveAuthorization,
    },
    {
      label: `Reset Swipe Attempts`,
      handler: () => resetSwipeAttempts(getPendingAccessorial(move.accessorials)),
      disabled: move.settled,
      hide: !hasActiveAuthorization,
    },
    {
      label: `Expire Pending Accessorial`,
      handler: () => expirePendingAccessorial(getPendingAccessorial(move.accessorials)),
      hide: !hasActiveAuthorization,
    },
  ];
  const arActions = [
    {
      label: `Go To Invoice Details`,
      handler: () => goToInvoice(move.accountsReceivable.invoice.id, move.customer.id, move.accountsReceivable.id),
      hide: !move.accountsReceivable || !move.accountsReceivable.active,
    },
    {
      label: `Recalculate Customer Price`,
      handler: () => rerunAR(move.id),
      disabled: move.settled,
    },
    {
      label: `Review/Discount`,
      handler: () => handleARReviewModalOpen(move.accountsReceivable),
      disabled: move.settled && userRole !== 'admin',
      hide: !move.accountsReceivable || !move.accountsReceivable.active,
    },
    {
      label: move?.price_override ? `Edit Price Override` : `Add Price Override`,
      handler: () => handleARPriceOverrideModalOpen(move.id),
      disabled: move?.accountsReceivable?.paid_amount || move.settled,
    },
    {
      label: `View AR Move Logs`,
      handler: () => handleARLogsModalOpen(`move`, move.accountsReceivable.eventsByEventId),
      hide: !move.accountsReceivable || !move.accountsReceivable.eventsByEventId,
    },
  ];
  const apActions = [
    {
      label: 'Recalculate Driver Pay',
      handler: () => rerunAP(move.id),
      disabled: move.settled,
    },
  ];

  return (
    <>
      <MoveTimeModal open={timeModal.open} onClose={handleTimeModalClose} input={timeModal.input} />
      <MoveStatusModal open={statusModal.open} onClose={handleStatusModalClose} input={statusModal.input} />
      <ARReviewModal open={arReviewModal.open} onClose={handleARReviewModalClose} input={arReviewModal.input} />
      <ARLogsModal open={arLogsModal.open} onClose={handleARLogsModalClose} input={arLogsModal.input} />
      <ARPriceOverrideModal
        open={arPriceOverrideModal.open}
        onClose={handleARPriceOverrideModalClose}
        input={arPriceOverrideModal.input}
      />
      <MoveOutcome
        open={moveOutcomeModal.open}
        onClose={handleMoveOutcomeModalClose}
        renderType={`page`}
        input={moveOutcomeModal.input}
      />
      {/* <ManageAccessorialsModal open={accessorialsModal} close={handleAccessorialsModalClose} moveId={move.id} /> */}
      <SpendAuthorizationModal
        open={spendAuthModal}
        close={handleSpendAuthModalClose}
        moveId={move.id}
        customerId={move.customer?.id}
      />
      <AuthorizationWindowModal
        open={authWindowModal}
        close={handleAuthWindowModalClose}
        save={handleAuthWindowModalSave}
        pendingAcc={getPendingAccessorial(move.accessorials)}
        pickupTime={move.pickup_time}
        durationSec={move.lane.duration_sec}
      />
      {/* <CancelMoveModal
        open={cancelModal}
        input={{ move }}
        move={move}
        onSave={async cancelStatus => {
          handleCancelModalClose(cancelStatus);
        }}
        onClose={() => {
          handleCancelModalClose();
        }}
      /> */}

      <DriversProvider refreshSeconds={500}>
        <HangtagManagementModal
          open={hangtagManagementModal}
          move={move}
          setHangtagManagementModal={setHangtagManagementModal}
          onClose={handleHangtagManagementModalClose}
        />
      </DriversProvider>
      <VinOverrideModal
        open={vinOverrideModal}
        move={move}
        setVinOverrideModal={setVinOverrideModal}
        vinOverrideHash={vinOverrideHash}
        onClose={() => {
          handleVinOverrideModalClose();
        }}
      />
      <TrackingLinkModal
        open={trackingLinkModal}
        move={move}
        setTrackingLinkModal={setTrackingLinkModal}
        conciergeUrl={conciergeUrl}
        onClose={() => {
          handleTrackingLinkModalClose();
        }}
      />
      <EditLaneModal open={editLaneModalOpen} close={handleEditLaneModalClose} move={move}/>

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Grid container spacing={1} alignItems='center'>
            <Grid item>
              <IconButton className={cls.iconBtn} onClick={() => goToPreviousPage()}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </Grid>

            <Grid item xs>
              <Typography className={cls.head}>Move Details</Typography>
            </Grid>

            {move.settled ? (
              <Grid item>
                <Typography className={cls.settled}>Move is settled</Typography>
              </Grid>
            ) : (
              <Grid item>
                <Tooltip placement='top' title={`Click to manage this move's outcome`}>
                  <Button
                    className={cls.saveBtn}
                    color='default'
                    onClick={() => handleMoveOutcomeModalOpen({ move })}
                    disabled={move.settled}
                  >
                    Manage Outcome
                  </Button>
                </Tooltip>
              </Grid>
            )}

            {editMode ? (
              <Grid item>
                <Tooltip placement='top' title={`Click to lock & save your changes`}>
                  <Button
                    className={cls.saveBtn}
                    color='primary'
                    onClick={() => handleSaveChanges(move)}
                    disabled={move.settled}
                  >
                    Save Changes
                  </Button>
                </Tooltip>
              </Grid>
            ) : null}

            <Grid item>
              <Tooltip
                placement='top'
                title={
                  move.settled
                    ? `Move is settled and can no longer be edited`
                    : editMode
                    ? `Click to lock & discard your changes`
                    : `Click to unlock & edit the move`
                }
              >
                <IconButton
                  style={{
                    color: move.settled
                      ? theme.palette.text.disabled
                      : editMode
                      ? theme.palette.error.main
                      : theme.palette.text.secondary,
                  }}
                  className={cls.iconBtn}
                  onClick={() => handleEditMode()}
                >
                  <Icon>{editMode ? `lock_open` : `lock`}</Icon>
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip placement='top' title={`Actions`}>
                <IconButton className={cls.iconBtn} onClick={handleActionsOpen}>
                  <Icon>settings</Icon>
                </IconButton>
              </Tooltip>
              <Menu
                keepMounted
                id={`move-actions-menu`}
                anchorEl={actionsOpen}
                open={Boolean(actionsOpen)}
                onClose={handleActionsClose}
              >
                {globalActions.map((action, i) => {
                  if (!action || action.hide) return null;
                  if (!action.handler) return <Divider key={`move-action-${i}`} className={cls.divider} />;
                  return (
                    <MenuItem key={`move-action-${i}`} onClick={() => handleAction(action)} disabled={action.disabled}>
                      {action.label || `Action ${i + 1}`}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          </Grid>

          <div className={cls.break_lg} />

          {/* MOVE STATUS TRACKER */}
          <MoveStatusTracker move={move} size='large' />

          <div className={cls.break_lg} />

          {/* MOVE INFO */}
          <MoveInfo
            move={move}
            editMode={editMode}
            mainImageUrl={mainImageUrl}
            editVals={{
              active,
              autoAssign,
              chargeable,
              color,
              consumerName,
              consumerPhone,
              consumerPickup,
              dealerContact,
              lyftFlag,
              make,
              manualFlag,
              model,
              moveTemplate,
              moveType,
              notes,
              odometer,
              payable,
              priority,
              rateClass,
              rateClassOverride,
              refNum,
              rideType,
              stock,
              tags,
              vin,
              year,
              weight,
              vehicleWeightMetadata,
              config,
            }}
            editSetVals={{
              setActive,
              setAutoAssign,
              setChargeable,
              setColor,
              setConsumerName,
              setConsumerPhone,
              setConsumerPickup,
              setDealerContact,
              setLyftFlag,
              setMake,
              setManualFlag,
              setModel,
              setMoveTemplate,
              setMoveType,
              setNotes,
              setOdometer,
              setPayable,
              setPriority,
              setRateClass,
              setRateClassOverride,
              setRefNum,
              setRideType,
              setStock,
              setTags,
              setVin,
              setYear,
              setWeight,
              setVehicleWeightMetadata,
              setConfig,
            }}
          />

          {/* LANE/LOCATION INFO */}
          <Divide spacer tip='View the lane this move is associated with.' actions={laneActions}>
            Lane
          </Divide>
          <MoveLane move={move} />
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <MoveLocation type='pickup' move={move} editMode={false}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <MoveLocation type='delivery' move={move} editMode={false}/>
            </Grid>
          </Grid>

          {/* TRACKING */}
          <Divide spacer tip='Track where the move is currently located.'>
            Tracking
          </Divide>
          <MoveTracking move={move} />

          {/* IMAGES */}
          {pickupPhotos?.length || deliveryPhotos?.length || fuelPhotos?.length ? (
            <>
              <Divide spacer tip='View images from the pickup and delivery.'>
                Images
              </Divide>
              <div className={cls.paper}>
                <TextField
                  select
                  fullWidth
                  size='small'
                  variant='outlined'
                  label='Image Set Selection'
                  placeholder='Select an image set...'
                  value={imageSelection}
                  onChange={e => setImageSelection(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                        <Icon color='disabled' fontSize='small'>
                          burst_mode
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                >
                  <MenuItem value='pickup'>Pickup Images - {pickupName}</MenuItem>
                  <MenuItem value='delivery'>Delivery Images - {deliveryName}</MenuItem>
                  <MenuItem value='fuel'>Fuel Images</MenuItem>
                </TextField>

                <Spacer size='xs' />

                <ImageCarousel size='lg' images={getImageSelection()} />
              </div>
            </>
          ) : null}

          {/* AR/AP INFO */}
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Divide spacer tip='View what the customer is being charged for this move.' actions={arActions}>
                Customer Price
              </Divide>
              <MoveAR move={move} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Divide spacer tip='View what the driver is being paid for this move.' actions={apActions}>
                Driver Pay
              </Divide>
              <MoveAP move={move} />
            </Grid>
          </Grid>

          {/* ACCESSORIALS INFO */}
          {move?.accessorials?.length ? (
            <>
              <Divide
                spacer
                tip='View accessorials and spend authorizations placed on this move.'
                actions={accessorialActions}
              >
                Accessorials
              </Divide>
              <MoveAccessorials move={move} accessorials={move.accessorials} />
            </>
          ) : null}

          {/* PROFIT BREAKDOWN */}
          {move?.accountsReceivable && move?.appayments?.length && userRole === `admin` ? (
            <>
              <Divide spacer tip='View the profits and costs associated with this move.'>
                Profit & Loss Breakdown
              </Divide>
              <MoveBreakdown move={move} />
            </>
          ) : null}

          {/* RELATED MOVES */}
          {relatedMoves?.length ? (
            <>
              <Divide spacer tip='View moves related to this move by Plan ID.'>
                Related Moves
              </Divide>
              <MoveRelated relatedMoves={relatedMoves} />
            </>
          ) : null}

          {/* AUDIT LOGS TIMELINE */}
          <Divide spacer tip='Timeline of actions taken on this move.'>
            Audit Timeline
          </Divide>
          <MoveAuditTimeline move={move} />
        </Container>
      </div>
    </>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  iconBtn: {
    width: 40,
    height: 40,
  },
  saveBtn: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  head: {
    lineHeight: 1,
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  settled: {
    lineHeight: 1,
    fontSize: 16,
    // fontWeight: 600,
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  divider: {
    marginTop: 4,
    marginBottom: 4,
  },
  break_lg: {
    width: '100%',
    height: theme.spacing(3),
  },
}));

//////////////////////// EXPORT ////////////////////////

export default MoveDetails;
