//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { makeStyles, Grid, IconButton, Icon, Tooltip, Badge } from '@material-ui/core';

import Spacer from '../../../components/Spacer';
import { useSettings } from '../providers/SettingsProvider';
import { useUnassignedMoves } from '../providers/UnassignedMovesProvider';

//////////////////////// COMPONENT ////////////////////////

export default function PlansToolbarOptions(props) {
  const cls = useStyles();

  const {
    moveDrawerOpen,
    setMoveDrawerOpen,
    driverDrawerOpen,
    setDriverDrawerOpen,
    cancelDrawerOpen,
    setCancelDrawerOpen,
    openDebugModal,
  } = useSettings();
  const { unassignedMovesCount } = useUnassignedMoves();

  return (
    <Grid container wrap='nowrap'>
      <Grid item>
        <Tooltip title='Open Unassigned Moves List'>
          <IconButton
            className={cls.iconBtn}
            onClick={() => {
              setMoveDrawerOpen(!moveDrawerOpen);
            }}
          >
            <Badge badgeContent={unassignedMovesCount} className={cls.iconMoves}>
              <Icon className={cls.iconMoves}>drive_eta</Icon>
            </Badge>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Open Drivers List'>
          <IconButton
            className={cls.iconBtn}
            data-testid='drivers-btn'
            onClick={() => {
              setDriverDrawerOpen(!driverDrawerOpen);
            }}
          >
            <Icon className={cls.iconDrivers}>group</Icon>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Open Canceled Moves List'>
          <IconButton
            className={cls.iconBtn}
            onClick={() => {
              setCancelDrawerOpen(!cancelDrawerOpen);
            }}
          >
            <Icon className={cls.iconCancel}>block</Icon>
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip title='Open Debug Menu'>
          <IconButton className={cls.iconBtn} onClick={() => openDebugModal()}>
            <Icon>bug_report</Icon>
          </IconButton>
        </Tooltip>
      </Grid>

      {/* <Grid item>
        <Tooltip title='Open Settings Menu'>
          <IconButton className={cls.iconBtn}>
            <Icon>settings</Icon>
          </IconButton>
        </Tooltip>
      </Grid> */}

      <Spacer column size='xs' />
    </Grid>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  iconBtn: {
    padding: theme.spacing(1),
  },
  iconMoves: {
    color: theme.palette.primary.main,
  },
  iconDrivers: {
    color: theme.palette.secondary.main,
  },
  iconCancel: {
    // color: theme.palette.error.main,
  },
}));
