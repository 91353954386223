//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { Howl } from 'howler';
import { useUserDetails } from '../../providers/UserDetailsProvider';

import { makeStyles, AppBar, Toolbar, IconButton, Icon, Grid, Chip, Tooltip } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useSubscription } from '@apollo/client';
import { getAuth, signOut } from 'firebase/auth'
import { getUserEmail, getUserId } from '../../utils/authHelper'
import { useRegionsGlobals } from '../../providers/RegionsProvider';
import { useNotificationsGlobals } from '../../providers/NotificationsProvider';

import { GET_NOTIFICATIONS } from './gql';

import Searchbar from './Searchbar';
import PendingCancelIcon from './PendingCancelIcon';
import NotificationIcon from './Notifications/NotificationIcon';
import NotificationDrawer from './Notifications/NotificationDrawer';
import notificationAlert from '../../static/notificationalert.mp3';

import hopdriveLogo from '../../static/logo-banner-light.svg';

const production = process.env.REACT_APP_ENV === `production` ? true : false;

//////////////////////// COMPONENT ////////////////////////

function NavTopBar({ onMenu }) {
  const firebase = getAuth()
  const history = useHistory()
  const ctx = useUserDetails();
  const cls = useStyles();

  const newNotificationSound = new Howl({
    src: [notificationAlert],
    volume: 0.25,
  });

  const { loading, error, data } = useSubscription(GET_NOTIFICATIONS);
  const [badgeNumber, setBadgeNumber] = React.useState(null);
  const [filteredNotifications, setFilteredNotifications] = React.useState([]);
  const [userEmail, setUserEmail] = React.useState(null)
  const [loggedIn, setLoggedIn] = React.useState(false)

  const { readList, assignedTo, type, assignmentBell } = useNotificationsGlobals();

    React.useEffect(() => {
      if (loggedIn) {
        let email = getUserEmail()
        setUserEmail(email)
      }
    }, [loggedIn])

  React.useEffect(() => {
    setBadgeNumber(filteredNotifications.length);
  }, [filteredNotifications]);

  var notifications;
  var filteredByAssignment;
  var filteredByReadList;
  var filteredByUnassigned;
  var dataLength;

  React.useEffect(() => {
    if (localStorage.getItem('read-notifications') === null) {
      localStorage.setItem('read-notifications', '[]');
    }
  }, []);

  React.useEffect(() => {
    if (firebase.currentUser) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
      history.push('/login')
    }
  }, [firebase.currentUser])

  React.useEffect(() => {
    const generateFilteredNotifications = () => {
      if (data && readList) {
        notifications = data.notifications;
        filteredByReadList = notifications.filter(
          notification => !readList.includes(notification.id) && notification.type === 'inform'
        );
        filteredByAssignment = notifications.filter(
          notification =>
            notification.type === 'action' && notification.assignedto === userEmail && notification.resolvedby === null
        );
        filteredByUnassigned = notifications.filter(
          notification => notification.type === 'action' && notification.assignedto === null
        );
        setFilteredNotifications([...filteredByReadList, ...filteredByAssignment, ...filteredByUnassigned]);
      }
    };
    generateFilteredNotifications();
  }, [data, readList]);

  React.useEffect(() => {
    if (userEmail) {
      const playSound = async () => {
        newNotificationSound.play();
      };
      if (assignmentBell && userEmail && assignedTo === userEmail) {
        playSound();
      }
    }
  }, [assignedTo]);

  if (data) {
    dataLength = data.notifications.length;
  }

  React.useEffect(() => {
    const playSound = async () => {
      newNotificationSound.play();
    };
    if (assignmentBell && userEmail && (type === 'inform' || assignedTo === null || assignedTo === userEmail)) {
      playSound();
    }
  }, [dataLength]);

  const userLogout = async () => {
    const userId = await getUserId()
    if (firebase.currentUser) {
      ctx.writeUserEventlog(userEmail, userId, 'user.logout', false)
      signOut(firebase);
      setLoggedIn(false)
    } 
    history.push('/login')
  };

  const { setRegionModalOpen } = useRegionsGlobals();

  const [notificationDrawerOpen, setNotificationDrawerOpen] = React.useState(false);

  // Open/Close slidable sidebar drawer
  const handleMenuOpen = () => {
    if (onMenu) onMenu(true);
  };

  return loggedIn ? (
    
    <AppBar className={production ? cls.appbar_prod : cls.appbar_test}>
      <Toolbar className={cls.toolbar}>
        <Grid container spacing={0} alignItems='center'>
          <Grid item>
            <Tooltip title='Menu'>
              <IconButton color='inherit' onClick={() => handleMenuOpen(true)}>
                <Icon>menu</Icon>
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Link to='/plans'>
              <img className={cls.titleLogo} alt='HopDrive Logo' width='180px' src={hopdriveLogo} />
            </Link>
          </Grid>

          <Grid item>
            <Spacer column />
          </Grid>

          <Grid item>
            <Chip
              classes={{
                root: cls.titleChip,
              }}
              label={production ? `Admin Portal` : `Admin Portal - Test`}
              size='small'
              variant='outlined'
              icon={<Icon className={cls.titleChipIcon}>{production ? `manage_accounts` : `construction`}</Icon>}
            />
          </Grid>

          <Grid item xs />

          <Grid item>
            <Searchbar />
          </Grid>

          <Grid item>
            <Tooltip title='Change Selected Regions'>
              <IconButton onClick={() => setRegionModalOpen(true)} color='inherit'>
                <Icon>public</Icon>
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <PendingCancelIcon />
          </Grid>

          <Grid item>
            <IconButton color='inherit' onClick={() => setNotificationDrawerOpen(true)}>
              <NotificationIcon loading={loading} data={data} error={error} badgeNumber={badgeNumber} />
            </IconButton>

            {notificationDrawerOpen ? (
              <NotificationDrawer
                loading={loading}
                data={data}
                error={error}
                notificationDrawerOpen={notificationDrawerOpen}
                setNotificationDrawerOpen={setNotificationDrawerOpen}
                filteredNotifications={filteredNotifications}
                setFilteredNotifications={setFilteredNotifications}
                userEmail={userEmail}
              />
            ) : null}
          </Grid>

          <Grid item>
            <Tooltip title='Profile'>
              <IconButton color='inherit' component={Link} to='/profile'>
                <Icon>account_circle</Icon>
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title='Logout'>
              <IconButton color='inherit' onClick={() => userLogout()}>
                <Icon>exit_to_app</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  ) : (
    <AppBar className={production ? cls.appbar_prod : cls.appbar_test}>
      <Toolbar className={cls.toolbar}>
        <Grid item>
          <img className={cls.titleLogo} alt='HopDrive Logo' width='180px' src={hopdriveLogo} />
        </Grid>
        <Grid item>
            <Spacer column />
          </Grid>
        <Grid item>
            <Chip
              classes={{
                root: cls.titleChip,
              }}
              label={production ? `Admin Portal` : `Admin Portal - Test`}
              size='small'
              variant='outlined'
              icon={<Icon className={cls.titleChipIcon}>{production ? `manage_accounts` : `construction`}</Icon>}
            />
          </Grid>
      </Toolbar>
    </AppBar>  
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  appbar_prod: {
    background: theme.palette.nav.prod,
    boxShadow: theme.shadow.nav,
  },
  appbar_test: {
    background: theme.palette.nav.test,
    boxShadow: theme.shadow.nav,
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  titleTxt: {
    lineHeight: 1,
    fontSize: 24,
    // fontWeight: 500,
  },
  titleLogoBox: {
    '& svg': {
      '& path': {
        fill: theme.palette.primary.main,
      },
      '& rect': {
        fill: theme.palette.primary.main,
      },
      '& polygon': {
        fill: theme.palette.primary.main,
      },
    },
  },
  titleLogo: {
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  titleChip: {
    paddingLeft: 2,
    border: `1px solid ${theme.palette.text.contrastFade}`,
    color: theme.palette.text.contrastFade,
    '& .MuiChip-icon': {
      color: theme.palette.text.contrastFade,
      transition: '0.15s',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.text.contrast}`,
      color: theme.palette.text.contrast,
      '& .MuiChip-icon': {
        color: theme.palette.text.contrast,
      },
    },
    cursor: 'pointer',
    transition: '0.15s',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  titleChipIcon: {
    fontSize: 20,
  },
}));

//////////////////////// EXPORT ////////////////////////

export default withRouter(NavTopBar);
