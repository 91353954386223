import { gql } from '@apollo/client';

const GET_MOVE_DETAILS = gql`
  subscription get_move_details($id: bigint!) {
    moves(where: { id: { _eq: $id } }) {
      id
      active
      auto_assign
      cancel_reason
      cancel_status
      chargeable
      class
      config
      consumer_name
      consumer_phone
      consumer_pickup
      consumer_type
      dealer_contact
      deliver_by
      delivery_arrived
      delivery_started
      delivery_stop_id
      delivery_successful
      delivery_template_override
      delivery_time
      delivery_workflow_data
      driver_app_version
      driver_id
      driver_name
      driver_status
      lyft_flag
      lyft_trigger_id
      manual_flag
      move_details
      move_failed
      move_type
      parent_move_id
      payable
      pickup_arrived
      pickup_started
      pickup_stop_id
      pickup_successful
      pickup_template_override
      pickup_time
      pickup_workflow_data
      plan_id
      priority
      rate_class_override
      ready_by
      reference_num
      return_ride_id
      ride_type
      settled
      status
      tags
      tookan_relationship_id
      tracking_link
      trip_id
      vehicle_color
      vehicle_gross_weight_lbs
      vehicle_image
      vehicle_make
      vehicle_model
      vehicle_odometer
      vehicle_stock
      vehicle_vin
      vehicle_year
      accessorials {
        id
        ap_amount
        ar_amount
        code
        cost
        notes
        status
        authorization {
          id
          auth_attempts
          card_id
          created_at
          created_by
          driver_id
          driver_name
          last_declined_at
          last_declined_reason
          last4
          max_charge
          merchant_city
          merchant_name
          merchant_postalcode
          merchant_state
          updated_at
          valid_from
          valid_to
          vendor_auth_id
        }
        move {
          active
        }
      }
      accountsReceivable {
        id
        active
        discount_amount
        discount_reason
        dispute_reason
        disputed
        due_amount
        move_id
        notes
        paid_amount
        status
        invoice_id
        details {
          id
          amount
          name
          notes
          rate_source
          documents {
            id
            mime_type
            name
            url
          }
          raterule {
            id
            class
            class_3_rate
            class_4_rate
            class_5_rate
            class_6_rate
            distance_end
            distance_start
            rate
            type
            raterulegroup {
              id
              name
              begin_date
              end_date
              customer {
                id
                name
              }
            }
          }
        }
        eventsByEventId {
          id
          createdat
          invoice_id
          message
          move_id
        }
        invoice {
          id
          accounting_num
          end_datetime
          start_datetime
          status
        }
        revisions(order_by: { revision: desc }) {
          id
          discount_amount
          discount_reason
          dispute_reason
          disputed
          due_amount
          notes
          paid_amount
          revision
          details {
            id
            amount
            name
            notes
            rate_source
            documents {
              id
              mime_type
              name
              url
            }
            raterule {
              id
              class
              class_3_rate
              class_4_rate
              class_5_rate
              class_6_rate
              distance_end
              distance_start
              rate
              type
              raterulegroup {
                id
                name
                begin_date
                end_date
                customer {
                  id
                  name
                }
              }
            }
          }
        }
      }
      appayments {
        id
        amount
        notes
        status
        type
        accessorial {
          id
          code
          notes
        }
        move {
          id
          move_type
        }
      }
      childMoves(where: { active: { _eq: 1 } }, order_by: { pickup_time: asc }) {
        id
        active
        cancel_status
        consumer_name
        consumer_phone
        consumer_type
        deliver_by
        delivery_arrived
        delivery_started
        delivery_successful
        delivery_time
        manual_flag
        move_type
        pickup_arrived
        pickup_started
        pickup_successful
        pickup_time
        ready_by
        reference_num
        tracking_link
        vehicle_color
        vehicle_image
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        accessorials {
          id
          ap_amount
          ar_amount
          code
          cost
        }
        accountsReceivable {
          id
          discount_amount
          due_amount
        }
        appayments {
          id
          amount
          notes
          status
          type
          accessorial {
            id
            code
            notes
          }
          move {
            id
            move_type
          }
        }
        childMoves(where: { active: { _eq: 1 } }, order_by: { pickup_time: asc }) {
          id
          active
          move_type
        }
        customer {
          id
          name
        }
        lane {
          id
          distance_miles
          pickup {
            address
          }
        }
      }
      customer {
        id
        name
      }
      driver {
        id
        driver_app_version
        user {
          id
          avatar_url
          display_name
        }
      }
      driverPayRate {
        distance_miles
        final_driver_base_pay
        final_driver_return_pay
        final_source
        pay_rate_group_type
        region_source
        appliedPayRateGroup {
          description
          name
        }
        appliedPayRateRule {
          id
          distance_end
          distance_start
          per_mile_rate
          per_minute_rate
        }
        appliedRegion {
          id
          name
        }
      }
      eventlogs {
        id
        driver_id
        event_time_utc
        metadata
        user
        actiontype {
          description
          name
          eventlogs_aggregate(where: { move: { id: { _eq: $id } } }) {
            aggregate {
              count
            }
          }
        }
        roletype {
          name
        }
      }
      hangtags {
        id
        print_layout
        status
        rear_code
        type
        updated_at
        updated_by
        url
        hash_id
        move_id
        driver_id
        workflow_id
      }
      lane {
        id
        average_drive_speed_mph
        dealer_base_price
        dealer_base_rate_type
        dealer_stranded_price
        dealer_stranded_rate_type
        description
        distance_miles
        driver_base_pay
        driver_pay_per_minute
        driver_return_pay
        duration_sec
        pickup_inspection_sec
        delivery_inspection_sec
        estimated_rideshare_return_cost
        insurance_cost
        pickup {
          id
          address
          latitude
          longitude
          name
          region {
            name
            id
          }
        }
        delivery {
          id
          address
          latitude
          longitude
          name
          region {
            name
            id
          }
        }
      }
      lyftrides {
        attempts(order_by: { id: desc }) {
          id
          createdat
          driver_first_name
          driver_phone
          driver_rating
          driver_vehicle_color
          driver_vehicle_license_plate
          driver_vehicle_license_plate_state
          driver_vehicle_make
          driver_vehicle_model
          driver_vehicle_year
          estimated_ride_cost
          requested_at
          ride_cost
          ride_distance
          ride_duration
          ride_type
          status
        }
      }
      moveByReturnRideId {
        id
        active
        cancel_status
        driver_id
        driver_name
        lyft_trigger_id
        ride_type
        tags
        appayments(where: { type: { _eq: "move pay" } }) {
          id
          amount
          type
        }
        lane {
          id
          pickup {
            id
            latitude
            longitude
            name
          }
        }
        lyft_trigger_move {
          id
          driver_name
        }
        movesByLyftTriggerId(order_by: { id: desc }) {
          id
          driver_id
          driver_name
          parentMove {
            id
          }
        }
        parentMove {
          id
        }
      }
      price_override {
        id
        active
        amount
        config
        created_by
        updated_by
        created_at
        updated_at
      }
      parentMove {
        id
        active
        cancel_status
        consumer_name
        consumer_phone
        consumer_type
        deliver_by
        delivery_arrived
        delivery_started
        delivery_successful
        delivery_time
        manual_flag
        move_type
        pickup_arrived
        pickup_started
        pickup_successful
        pickup_time
        ready_by
        reference_num
        tracking_link
        vehicle_color
        vehicle_image
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
        accessorials {
          id
          ap_amount
          ar_amount
          code
          cost
        }
        accountsReceivable {
          id
          discount_amount
          due_amount
        }
        appayments {
          id
          amount
          notes
          status
          type
          accessorial {
            id
            code
            notes
          }
          move {
            id
            move_type
          }
        }
        childMoves(where: { active: { _eq: 1 } }, order_by: { pickup_time: asc }) {
          id
          active
          move_type
          customer {
            id
            name
          }
        }
        customer {
          id
          name
        }
        lane {
          id
          distance_miles
          pickup {
            address
          }
        }
      }
      payer {
        id
        name
      }
      plan {
        id
        moves(order_by: { id: asc }) {
          id
          cancel_status
          move_type
          reference_num
          ride_type
          status
          vehicle_color
          vehicle_make
          vehicle_model
          vehicle_odometer
          vehicle_stock
          vehicle_vin
          vehicle_year
          lane {
            id
            description
          }
        }
      }
      price_override {
        id
        active
        amount
        config
        created_at
        created_by
        updated_at
        updated_by
      }
      raterule {
        id
        class
        class_3_rate
        distance_end
        distance_start
        rate
        type
        raterulegroup {
          id
          name
          begin_date
          end_date
          customer {
            id
            name
          }
        }
      }
      sla {
        id
        label
        duration_hrs
      }
      pickup_photos: vehiclephotos(where: { workflow: { type: { _eq: "pickup" } }, status: { _eq: "done" } }) {
        id
        location
        name
        step_id
        url
      }
      delivery_photos: vehiclephotos(where: { workflow: { type: { _eq: "delivery" } }, status: { _eq: "done" } }) {
        id
        location
        name
        step_id
        url
      }
      fuel_photos: vehiclephotos(where: { workflow: { type: { _eq: "fuel" } }, status: { _eq: "done" } }) {
        id
        location
        name
        step_id
        url
      }
    }
  }
`;

const UPDATE_TIMES_MOVE_DETAILS = gql`
  mutation update_times_move_details(
    $id: bigint!
    $readyTime: timestamptz
    $pickupTime: timestamptz
    $deliveryTime: timestamptz
  ) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: { ready_by: $readyTime, pickup_time: $pickupTime, delivery_time: $deliveryTime, updatedat: "now()" }
    ) {
      affected_rows
      returning {
        id
        ready_by
        pickup_time
        delivery_time
        updatedat
      }
    }
  }
`;

const UPDATE_STATUSES_MOVE_DETAILS = gql`
  mutation update_statuses_move_details(
    $id: bigint!
    $status: String
    $pickupStarted: timestamptz
    $pickupArrived: timestamptz
    $pickupSuccessful: timestamptz
    $deliveryStarted: timestamptz
    $deliveryArrived: timestamptz
    $deliverySuccessful: timestamptz
  ) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: {
        status: $status
        pickup_started: $pickupStarted
        pickup_arrived: $pickupArrived
        pickup_successful: $pickupSuccessful
        delivery_started: $deliveryStarted
        delivery_arrived: $deliveryArrived
        delivery_successful: $deliverySuccessful
        updatedat: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        status
        pickup_started
        pickup_arrived
        pickup_successful
        delivery_started
        delivery_arrived
        delivery_successful
        move_failed
        updatedat
      }
    }
  }
`;

const SAVE_MOVE_DETAILS = gql`
  mutation save_move_details(
    $id: bigint!
    $active: smallint!
    $auto_assign: smallint!
    $chargeable: Boolean!
    $class: String
    $config: jsonb
    $consumer_name: String
    $consumer_phone: String
    $consumer_pickup: Boolean!
    $dealer_contact: String
    $lyft_flag: smallint!
    $manual_flag: Boolean!
    $move_details: String
    $move_type: String!
    $payable: Boolean!
    $pickup_template_override: String
    $priority: bigint!
    $rate_class_override: smallint!
    $reference_num: String
    $ride_type: String
    $tags: String
    $vehicle_color: String
    $vehicle_gross_weight_lbs: numeric
    $vehicle_make: String
    $vehicle_model: String
    $vehicle_odometer: String
    $vehicle_stock: String
    $vehicle_vin: String
    $vehicle_year: String
  ) {
    update_moves(
      where: { id: { _eq: $id } }
      _set: {
        active: $active
        auto_assign: $auto_assign
        chargeable: $chargeable
        class: $class
        config: $config
        consumer_name: $consumer_name
        consumer_phone: $consumer_phone
        consumer_pickup: $consumer_pickup
        dealer_contact: $dealer_contact
        delivery_template_override: $pickup_template_override
        lyft_flag: $lyft_flag
        manual_flag: $manual_flag
        move_details: $move_details
        move_type: $move_type
        payable: $payable
        pickup_template_override: $pickup_template_override
        priority: $priority
        rate_class_override: $rate_class_override
        reference_num: $reference_num
        ride_type: $ride_type
        tags: $tags
        updatedat: "now()"
        vehicle_color: $vehicle_color
        vehicle_gross_weight_lbs: $vehicle_gross_weight_lbs
        vehicle_make: $vehicle_make
        vehicle_model: $vehicle_model
        vehicle_odometer: $vehicle_odometer
        vehicle_stock: $vehicle_stock
        vehicle_vin: $vehicle_vin
        vehicle_year: $vehicle_year
      }
    ) {
      affected_rows
      returning {
        id
        active
        auto_assign
        chargeable
        class
        config
        consumer_name
        consumer_phone
        consumer_pickup
        dealer_contact
        delivery_template_override
        lyft_flag
        manual_flag
        move_details
        move_type
        payable
        pickup_template_override
        priority
        rate_class_override
        reference_num
        ride_type
        tags
        updatedat
        vehicle_color
        vehicle_gross_weight_lbs
        vehicle_make
        vehicle_model
        vehicle_odometer
        vehicle_stock
        vehicle_vin
        vehicle_year
      }
    }
  }
`;

const UPDATE_MOVE_CANCEL = gql`
  mutation update_move_cancel(
    $moveId: bigint!
    $cancelStatus: String
    $cancelReason: String
    $moveFailed: timestamptz
    $chargeable: Boolean!
    $payable: Boolean!
  ) {
    update_moves(
      where: { id: { _eq: $moveId } }
      _set: {
        cancel_status: $cancelStatus
        cancel_reason: $cancelReason
        move_failed: $moveFailed
        chargeable: $chargeable
        payable: $payable
        updatedat: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        cancel_reason
        cancel_status
        move_failed
        status
        updatedat
      }
    }
  }
`;

const GET_SMS_MESSAGES = gql`
  query get_sms_messages($moveId: bigint!, $limit: Int) {
    smsmessages(where: { move_id: { _eq: $moveId } }, limit: $limit, order_by: { updatedat: desc }) {
      id
      error
      message_body
      recipient_phone
      sender_phone
      status
      updatedat
      template_id
      smsmedia {
        url
      }
    }
  }
`;

const GET_MOVE_AUDIT_LOGS = gql`
  query get_move_audit_logs($moveId: bigint!, $limit: Int) {
    eventlogs(where: { move_id: { _eq: $moveId } }, order_by: { event_time_utc: desc }, limit: $limit) {
      driver_id
      event_time_utc
      metadata
      user
      actiontype {
        description
        name
        eventlogs_aggregate(where: { move_id: { _eq: $moveId } }) {
          aggregate {
            count
          }
        }
      }
      roletype {
        name
      }
    }
  }
`;

const UPDATE_MOVE_UNPLAN = gql`
  mutation update_move_unplan($moveId: bigint!) {
    update_moves(
      where: { id: { _eq: $moveId } }
      _set: {
        trip_id: null
        plan_id: null
        pickup_stop_id: null
        delivery_stop_id: null
        tookan_relationship_id: null
        driver_name: null
        driver_id: null
        return_ride_id: null
        status: null
        pickup_started: null
        pickup_arrived: null
        pickup_successful: null
        delivery_started: null
        delivery_arrived: null
        delivery_successful: null
        tracking_link: null
        synced_with_tookan: null
        updatedat: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        driver_id
        driver_name
        pickup_started
        pickup_arrived
        pickup_successful
        delivery_started
        delivery_arrived
        delivery_successful
        pickup_stop_id
        delivery_stop_id
        plan_id
        return_ride_id
        status
        synced_with_tookan
        tookan_relationship_id
        tracking_link
        trip_id
        updatedat
      }
    }
  }
`;

const UPDATE_LOCATION_REGION = gql`
  mutation update_location_region($id: bigint!, $regionId: bigint) {
    update_locations(where: { id: { _eq: $id } }, _set: { region_id: $regionId }) {
      affected_rows
      returning {
        id
        region_id
      }
    }
  }
`;

const UPDATE_HANGTAG = gql`
  mutation update_hangtag($id: bigint!, $status: String!, $driver_id: bigint, $move_id: bigint, $updated_by: String!) {
    update_hangtags(
      where: { id: { _eq: $id } }
      _set: { status: $status, driver_id: $driver_id, move_id: $move_id, updated_at: "now()", updated_by: $updated_by }
    ) {
      affected_rows
      returning {
        id
        hash_id
        status
        driver_id
        move_id
      }
    }
  }
`;

export {
  GET_MOVE_DETAILS,
  UPDATE_TIMES_MOVE_DETAILS,
  UPDATE_STATUSES_MOVE_DETAILS,
  SAVE_MOVE_DETAILS,
  UPDATE_MOVE_CANCEL,
  GET_MOVE_AUDIT_LOGS,
  UPDATE_MOVE_UNPLAN,
  UPDATE_LOCATION_REGION,
  UPDATE_HANGTAG,
  GET_SMS_MESSAGES,
};
