//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import clsx from 'clsx';
import GoogleMap from 'google-map-react';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { getDistance } from 'geolib';

import { gql } from '@apollo/client';

import { useTheme, makeStyles, Typography, Icon } from '@material-ui/core';
import { Spacer } from '@hopdrive/storybook';

import { useData } from '../providers/DataProvider';
import { styles } from '../utils/googleMapOptions';

const log = false;

//////////////////////// FUNCTIONS ////////////////////////

/** Find the angle of 3 points (B is the center point)
 *
 * We use this as a way of detecting angles since the "heading" field is unreliable
 */
const getAngleOfThreePoints = (A, B, C) => {
  var AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
  var BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
  var AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
  return (Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)) * 180) / Math.PI;
};

/** Reduce the amount of points by an algorithm that checks distance and heading angle */
const getFilteredPoints = async points => {
  // Initialize filtered points
  let filteredPoints = [...points];

  // Using a for loop instead of a filter so we can modify the array while looping over it
  for (let fpIndex = 0; fpIndex < filteredPoints.length; fpIndex++) {
    // Initialize useful points
    const prevOfPrevPoint = filteredPoints[fpIndex - 2];
    const prevPoint = filteredPoints[fpIndex - 1];
    const curPoint = filteredPoints[fpIndex];
    const nextPoint = filteredPoints[fpIndex + 1];

    // Check if there is a previous and next point (so we always include first and last points)
    if (prevOfPrevPoint && prevPoint && curPoint && nextPoint) {
      // Get the distance between the current point and previous point
      const distanceToPrevPoint = getDistance(
        { latitude: prevPoint.lat, longitude: prevPoint.lng },
        { latitude: curPoint.lat, longitude: curPoint.lng },
        1
      );

      // Set the previous accurate heading angle based on the previous and previous before that points
      const prevAngle = getAngleOfThreePoints(
        { x: prevOfPrevPoint.lat, y: prevOfPrevPoint.lng },
        { x: prevPoint.lat, y: prevPoint.lng },
        { x: curPoint.lat, y: curPoint.lng }
      );

      // Set the current accurate heading angle based on the previous and next points
      const curAngle = getAngleOfThreePoints(
        { x: prevPoint.lat, y: prevPoint.lng },
        { x: curPoint.lat, y: curPoint.lng },
        { x: nextPoint.lat, y: nextPoint.lng }
      );

      // If the distance is not far enough away from the previous point, remove it and roll-back
      // If the previous point's angle is within a specific degree, remove it and roll-back
      const maxDistance = 15.24; // In Meters (1m = 3ft 3.37in)
      const maxAngle = 2; // In Degrees
      if (distanceToPrevPoint < maxDistance || Math.abs(prevAngle - curAngle) < maxAngle) {
        filteredPoints.splice(fpIndex, 1);
        fpIndex--;
      }
    }
  }

  // Remove falsey values from array
  filteredPoints = filteredPoints.filter(Boolean);

  // Return filtered points
  log && console.log(`Filtered Points:`, filteredPoints);
  return filteredPoints;
};

/** Call the Google Roads API to snap & smooth the points
 *
 * Google can only take in 100 points at a time which may lead to multiple API calls
 * We currently have a cap of 20 calls
 */
const getSmoothPoints = async (filteredPoints, apiKey) => {
  // Initialize smooth points
  let smoothPoints = [];

  // Try and use Google's Roads API to snap the points
  try {
    // Detect the number of API calls we have to do
    const apiCallCount = Math.ceil(filteredPoints.length / 100);

    // Throw an error if theres too many points being requested
    if (apiCallCount > 20) {
      throw new Error(
        `More than 20 API calls to Google were expected. Skipping Google call. Driver's path will not be snapped & smoothed...`
      );
    }

    // Build multiple arrays of points in segments of 100 so Google can work with the data
    const slicedPoints = [];
    for (let pIndex = 0; pIndex / 100 < apiCallCount; pIndex += 100) {
      const singleSlicedPoints = [...filteredPoints].slice(pIndex, pIndex + 100);
      slicedPoints.push(singleSlicedPoints);
    }

    // Do multiple API calls if necessary
    let res = await Promise.allSettled(
      slicedPoints.map(async (singleSlicedPoints, i) => {
        // Format points into a string for Google's API to use
        let combinedPoints = ``;
        singleSlicedPoints.forEach((p, i) => {
          combinedPoints += `${p.lat},${p.lng}`;
          if (i < singleSlicedPoints.length - 1) combinedPoints += `|`;
        });

        // Call Google's Roads API to snap the points to a road and smooth it out.
        const snapRes = await axios({
          url: `https://roads.googleapis.com/v1/snapToRoads?path=${combinedPoints}&interpolate=true&key=${apiKey}`,
          method: `GET`,
          headers: {
            'content-type': `application/json`,
          },
        });

        // Check for response
        // log && console.log(`Google Response #${i + 1}:`, snapRes);
        if (getPropValue(snapRes, `data.snappedPoints.length`)) {
          let resPoints = snapRes.data.snappedPoints;
          resPoints = resPoints.map(sp => {
            if (getPropValue(sp, `location.latitude`) && getPropValue(sp, `location.longitude`)) {
              return {
                lat: sp.location.latitude,
                lng: sp.location.longitude,
              };
            }
            return null;
          });
          return resPoints;
        }
      })
    );

    smoothPoints = res.map(e => e.value).flat();
  } catch (err) {
    console.error(`Driver's path was not snapped and smoothed:`, err);
  }

  // Remove falsey values from array
  smoothPoints = smoothPoints.filter(Boolean);

  // Return smooth points
  log && console.log(`Smooth Points:`, smoothPoints);
  return smoothPoints;
};

// /** Get the start time of the driver's route (to filter out excess data) */
// const getStart = move => {
//   if (move && move.pickup_time) {
//     if (move.pickup_started) return dayjs(move.pickup_started).format();
//     return dayjs(move.pickup_time).startOf(`day`).format();
//   }
//   return dayjs().startOf(`day`).format();
// };

// /** Get the end time of the driver's route (to filter out excess data) */
// const getEnd = move => {
//   if (move && move.pickup_time) {
//     if (move.delivery_successful) return dayjs(move.delivery_successful).format();
//     return dayjs(move.pickup_time).endOf(`day`).format();
//   }
//   return dayjs().endOf(`day`).format();
// };

//////////////////////// COMPONENT ////////////////////////

export default function DriverTracker({ move = {} }) {
  const theme = useTheme();
  const cls = useStyles();

  const { apolloClient } = useData();

  const [locs, setLocs] = React.useState([]);
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [etaTime, setEtaTime] = React.useState(null);
  const [etaMinutes, setEtaMinutes] = React.useState(null);

  const [loaded, setLoaded] = React.useState(false);
  const [renderer, setRenderer] = React.useState(null);
  const [service, setService] = React.useState(null);
  const [directionsRenderer, setDirectionsRenderer] = React.useState(null);
  const [directionsService, setDirectionsService] = React.useState(null);

  // Once the Google API is loaded, we can draw the polyline based on the driver location data
  React.useEffect(() => {
    // Fetch the driverlocations for this move and render the path on the map
    const fetchAndDrawDriverPath = async () => {
      try {
        // Check for move and lane
        if (!move || !move.lane || !move.lane.pickup || !move.lane.delivery)
          throw new Error(`Essential move data is missing!`);

        // Initialize Google API Key
        const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

        // Get the driverlocations data
        const res = await apolloClient.query({
          query: GET_DRIVER_LOCATIONS,
          variables: {
            moveId: move.id,
          },
        });

        // Set pickup and delivery locations
        let newLocs = [];
        const pickupLocation = { ...move.lane.pickup, type: `pickup_loc`, point: `pickup` };
        const deliveryLocation = { ...move.lane.delivery, type: `delivery_loc`, point: `delivery` };
        if (getPropValue(move, `lane.pickup`)) newLocs.push(pickupLocation);
        if (getPropValue(move, `lane.delivery`)) newLocs.push(deliveryLocation);

        // Check for response
        if (getPropValue(res, `data.driverlocations.length`)) {
          const driverlocations = res.data.driverlocations;
          log && console.log(`Raw Points:`, driverlocations);

          // Set driver location from the last driverlocations record
          const driverLocation = {
            id: `driver`,
            type: `driver_loc`,
            point: `driver`,
            latitude: driverlocations[driverlocations.length - 1].location.coordinates[0],
            longitude: driverlocations[driverlocations.length - 1].location.coordinates[1],
          };
          newLocs.push(driverLocation);
          setLocs(newLocs);

          // Format the points so it can be read by the map component
          const points = driverlocations.map(dl => {
            if (getPropValue(dl, `location.coordinates.length`)) {
              return {
                lat: dl.location.coordinates[0],
                lng: dl.location.coordinates[1],
              };
            }
            return null;
          });

          // Filter the points by using an algorithm that checks distance and angles
          let filteredPoints = await getFilteredPoints(points);

          // Smooth the points by using the Google Roads API
          let smoothPoints = await getSmoothPoints(filteredPoints, apiKey);

          // Get the route to render on the map (only when driving)
          if (move.status === `pickup started`) handleRoute(driverLocation, pickupLocation);
          if (move.status === `delivery started`) handleRoute(driverLocation, deliveryLocation);

          // Create the polyline from the driver path
          const driverPath = new service.Polyline({
            path: smoothPoints && smoothPoints.length ? smoothPoints : filteredPoints,
            geodesic: true,
            strokeColor: theme.palette.primary.main,
            strokeOpacity: move.status === `delivery successful` ? 1 : 0.333,
            strokeWeight: 4,
          });

          // Draw the path onto the map component
          driverPath.setMap(renderer);
        } else setLocs(newLocs);
      } catch (err) {
        console.error(`Error fetching driverlocations. The driver's path could not be drawn!`, err);
      }
    };

    if (!loaded && renderer && service && directionsRenderer && directionsService) {
      fetchAndDrawDriverPath();
      setLoaded(true);
    }

    // eslint-disable-next-line
  }, [renderer, service, directionsRenderer, directionsService]);

  // Function for getting center lat long coordinates based on locations
  let getCenterCoords = () => {
    if (locs.length) {
      let latSum = 0;
      let lonSum = 0;

      locs.forEach(loc => {
        latSum += loc.latitude || 0;
        lonSum += loc.longitude || 0;
      });

      const latAvg = latSum / locs.length;
      const lonAvg = lonSum / locs.length;
      return [latAvg, lonAvg];
    } else {
      return [37.5407, -77.436];
    }
  };

  // Handle clicking on a map marker
  const handleMarkerClick = loc => {
    if (selectedLocation && selectedLocation.id === loc.id) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(loc);
    }
  };

  // Handle loading of Google Maps API
  const handleGoogleApiLoaded = (apiRenderer, apiService) => {
    setRenderer(apiRenderer);
    setService(apiService);

    // Set Google Directions API renderer
    const newDirectionsRenderer = new apiService.DirectionsRenderer({
      polylineOptions: {
        geodesic: true,
        strokeColor: theme.palette.info.main,
        strokeOpacity: 1.0,
        strokeWeight: 4,
      },
      suppressMarkers: true,
      provideRouteAlternatives: false,
    });
    newDirectionsRenderer.setMap(apiRenderer);
    setDirectionsRenderer(newDirectionsRenderer);

    // Set Google Directions API service
    const newDirectionsService = new apiService.DirectionsService();
    setDirectionsService(newDirectionsService);
  };

  // Handle building the driver's route
  const handleRoute = async (origin, destination) => {
    try {
      directionsRenderer.setMap(renderer);

      // Throw an error if there's a malformed origin or destination
      if (
        !origin ||
        !origin.latitude ||
        !origin.longitude ||
        !destination ||
        !destination.latitude ||
        !destination.longitude
      ) {
        throw new Error(
          `Input for Google call was malformed. Skipping Google call. Driver's route was not rendered...`
        );
      }

      // Build the origin and destination strings to feed into Google
      const originStr = `${origin.latitude},${origin.longitude}`;
      const destinationStr = `${destination.latitude},${destination.longitude}`;

      // Build the request to Google
      const req = {
        origin: originStr,
        destination: destinationStr,
        travelMode: 'DRIVING',
      };

      // Call Google's Directions API to get the driver route
      directionsService.route(req, (res, status) => {
        if (status === 'OK') {
          // Render the route from the response
          // log && console.log(`Google Route Response:`, res);
          directionsRenderer.setDirections(res);

          // Set the ETA
          const durationSec = res.routes[0].legs[0].duration.value || 0;
          const durationMin = Math.round(durationSec / 60);
          const curTimePlusDurationSec = dayjs()
            .add(durationSec || 0, `seconds`)
            .format(`h:mm A`);

          setEtaTime(curTimePlusDurationSec);
          setEtaMinutes(durationMin);
        }
      });
    } catch (err) {
      console.error(`Driver's route could not be determined:`, err);
    }
  };

  // Location marker component
  const LocationMarker = ({ loc, useEta, useActive }) => {
    return (
      <div
        onClick={() => handleMarkerClick(loc)}
        className={clsx(cls.marker, {
          [cls.markerEta]: useEta && etaMinutes !== null,
          [cls.markerActive]: useActive,
          [cls.markerPickup]: loc.type === `pickup_loc`,
          [cls.markerDelivery]: loc.type === `delivery_loc`,
          [cls.markerDriver]: loc.type === `driver_loc`,
        })}
      >
        {/* ICON */}

        {loc.type === `pickup_loc` ? (
          <>
            {move.status === `pickup started` && etaMinutes !== null ? (
              <>
                <Typography className={cls.markerEtaTxt}>{etaMinutes}</Typography>
                <Typography className={cls.markerEtaUnitTxt}>MIN</Typography>
              </>
            ) : (
              <Typography className={cls.markerTxt}>P</Typography>
            )}
          </>
        ) : null}

        {loc.type === `delivery_loc` ? (
          <>
            {move.status === `delivery started` && etaMinutes !== null ? (
              <>
                <Typography className={cls.markerEtaTxt}>{etaMinutes}</Typography>
                <Typography className={cls.markerEtaUnitTxt}>MIN</Typography>
              </>
            ) : (
              <Typography className={cls.markerTxt}>D</Typography>
            )}
          </>
        ) : null}

        {loc.type === `driver_loc` ? <Icon className={cls.markerIcon}>account_circle</Icon> : null}

        {/* HOVER BOX */}

        <div className={cls.bubbleBox}>
          {/* TITLE */}

          {loc.type === `pickup_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.info.main }}>
                Pickup Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {loc.type === `delivery_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.success.main }}>
                Delivery Location
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {loc.type === `driver_loc` ? (
            <>
              <Typography className={cls.bubbleNameTxt} style={{ color: theme.palette.primary.main }}>
                {move.driver_name || `HopDriver`}
              </Typography>
              <div className={cls.bubbleLine} />
            </>
          ) : null}

          {/* NAME */}

          {loc.name ? <Typography className={cls.bubbleNameTxt}>{loc.name}</Typography> : null}

          {/* ADDRESS */}

          {loc.address ? (
            <>
              <Spacer size='xxs' />
              <Typography className={cls.bubbleAddressTxt}>{loc.address}</Typography>
            </>
          ) : null}

          {/* LAT/LON */}

          {loc.latitude && loc.longitude ? (
            <>
              <Spacer size='xxs' />
              <Typography className={cls.bubbleLatLonTxt}>
                {loc.latitude}, {loc.longitude}
              </Typography>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className={cls.mapContainer}>
      {/* ROUTE INFO */}
      {etaTime !== null && etaMinutes !== null ? (
        <div className={cls.mapInfo}>
          <Typography className={cls.mapInfoTxt}>
            ETA {etaTime} ({etaMinutes} min)
          </Typography>
        </div>
      ) : null}

      {/* MAP */}
      <GoogleMap
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
        center={getCenterCoords()}
        defaultZoom={12}
        options={{
          gestureHandling: `cooperative`,
          styles: styles,
        }}
        setOptions={{ clickableIcons: false }}
      >
        {/* LOCATION MARKERS */}
        {locs.map(loc => {
          if (Number(loc.latitude) && Number(loc.longitude)) {
            let useEta = false;
            if (
              (loc.type === `pickup_loc` && move.status === `pickup started`) ||
              (loc.type === `delivery_loc` && move.status === `delivery started`)
            ) {
              useEta = true;
            }
            const useActive = selectedLocation && selectedLocation.id === loc.id;

            return (
              <LocationMarker
                key={`location-marker-${loc.id}`}
                lat={loc.latitude}
                lng={loc.longitude}
                loc={loc}
                useEta={useEta}
                useActive={useActive}
              />
            );
          } else return null;
        })}
      </GoogleMap>
    </div>
  );
}

//////////////////////// STYLES ////////////////////////

const useStyles = makeStyles(theme => ({
  mapContainer: {
    zIndex: 1,
    position: 'relative',
    display: 'block',
    width: '100%',
    height: 720,
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    cursor: 'pointer',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: 540,
    },
    [theme.breakpoints.down('xs')]: {
      height: 400,
    },
  },
  mapInfo: {
    zIndex: 1,
    position: 'absolute',
    display: 'block',
    top: 10,
    left: 10,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 2,
    background: theme.palette.background.paper,
  },
  mapInfoTxt: {
    fontSize: 16,
    fontWeight: 500,
  },

  marker: {
    zIndex: 2,
    position: 'absolute',
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.text.contrast}`,
    borderRadius: '50%',
    transformOrigin: '50% 50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: theme.palette.text.contrast,
    transition: '0.1s',
    cursor: 'pointer',
    '& $bubbleBox': {
      display: 'none',
    },
    '&:hover': {
      zIndex: 4,
      width: 32,
      height: 32,
      border: `2px solid ${theme.palette.text.contrast}`,
      '& $bubbleBox': {
        display: 'block',
      },
    },
  },
  markerEta: {
    zIndex: 3,
    width: 32,
    height: 32,
    border: `2px solid ${theme.palette.text.contrast}`,
  },
  markerActive: {
    zIndex: 5,
    width: 32,
    height: 32,
    border: `2px solid ${theme.palette.text.contrast}`,
    '& $bubbleBox': {
      display: 'block',
    },
  },

  markerTxt: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 14,
    fontWeight: 500,
  },
  markerEtaTxt: {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 12,
    fontWeight: 700,
  },
  markerEtaUnitTxt: {
    position: 'absolute',
    top: '72%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 8,
    fontWeight: 300,
  },
  markerIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 16,
  },
  markerPickup: {
    color: theme.palette.info.contrast,
    background: theme.palette.info.main,
  },
  markerDelivery: {
    color: theme.palette.success.contrast,
    background: theme.palette.success.main,
  },
  markerDriver: {
    color: theme.palette.primary.contrast,
    background: theme.palette.primary.main,
  },

  icon: {
    filter: 'drop-shadow(1px 1px 1px #00000064)',
    transition: '0.1s',
  },

  bubbleBox: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    left: theme.spacing(7),
    minWidth: 192,
    maxWidth: 192,
    padding: theme.spacing(1),
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
    boxShadow: '1px 1px 2px #00000064',
    backgroundColor: theme.palette.background.paper,
  },
  bubbleLine: {
    width: '100%',
    height: 1,
    marginTop: 4,
    marginBottom: 6,
    background: theme.palette.divider,
  },
  bubbleNameTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  bubbleAddressTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  bubbleLatLonTxt: {
    lineHeight: 1.2,
    color: theme.palette.text.disabled,
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
}));

//////////////////////// GRAPHQL ////////////////////////

const GET_DRIVER_LOCATIONS = gql`
  query admin_getDriverLocations($moveId: bigint!) {
    driverlocations(where: { move_id: { _eq: $moveId } }, order_by: { time: asc }, limit: 5000) {
      id
      location
      time
    }
  }
`;

// Full list of query fields

// id
// accuracy
// activity_confidence
// activity_type
// altitude
// altitude_accuracy
// battery_is_charging
// battery_level
// driver_id
// event
// heading
// is_moving
// location
// mock
// move_id
// odometer
// speed
// time
